import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles/createTheme';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  sx?: SxProps<Theme>,
};

export function ArrowDownIcon({ sx }: Props) {
  return (
    <SvgIcon sx={{ width: 20, height: 20, viewBox: '0 0 20 20', ...sx }}>
      <path
        fill="currentColor"
        d="M12 16a1 1 0 0 1-.64-.23l-6-5a1 1 0 1 1 1.28-1.54L12 13.71l5.36-4.32a1 1 0 0 1 1.41.15a1 1 0 0 1-.14 1.46l-6 4.83A1 1 0 0 1 12 16"
      />
    </SvgIcon>
  );
}
