import type { Order, SparePart } from 'src/types/after-sales/spare-parts';

import { makeRequest, makeListRequest } from '../utils';

import type { TableRowsProps, ListRequestProps } from '../types';

// ----------------------------------------------------------------------

export function getSpareParts(paginationProps: ListRequestProps): Promise<TableRowsProps<SparePart>> {
  return makeListRequest<SparePart>(
    {
      url: `https://portal2test.electricway.no/api/frontend/v1/spare-parts`,
      paginationProps,
    }
  );
}

export function createOrder(order: Order<'set'>): Promise<Order<'get'>> {
  return makeRequest<Order<'set'>, { data: Order<'get'> }>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/cart/order`,
    data: order,
  }).then((response) => response.data.data);
}
