import type { IconButtonProps } from '@mui/material/IconButton';

import { m } from 'framer-motion';
import { useState, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { useBoolean } from 'src/hooks/use-boolean';

import { Label } from 'src/components/label';
import { Iconify } from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { Scrollbar } from 'src/components/scrollbar';
import { CustomTabs } from 'src/components/custom-tabs';

import { NotificationItem } from './notification-item';

import type { NotificationItemProps } from './notification-item';

// ----------------------------------------------------------------------

const TABS = [
  { value: 'all', label: 'All', count: 22 },
  { value: 'unread', label: 'Unread', count: 12 },
  { value: 'archived', label: 'Archived', count: 10 },
];

// ----------------------------------------------------------------------

export type NotificationsDrawerProps = IconButtonProps & {
  data?: NotificationItemProps[];
};

export function NotificationsDrawer({ data = [], sx, ...other }: NotificationsDrawerProps) {
  const drawer = useBoolean();

  const [currentTab, setCurrentTab] = useState('all');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const [notifications, setNotifications] = useState(data);

  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const handleMarkAllAsRead = () => {
    setNotifications(notifications.map((notification) => ({ ...notification, isUnRead: false })));
  };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )}

      <IconButton onClick={drawer.onFalse} sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>

      <IconButton>
        <Iconify icon="solar:settings-bold-duotone" />
      </IconButton>
    </Stack>
  );

  const renderTabs = (
    <CustomTabs variant="fullWidth" value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={
                (tab.value === 'unread' && 'info') ||
                (tab.value === 'archived' && 'success') ||
                'default'
              }
            >
              {tab.count}
            </Label>
          }
        />
      ))}
    </CustomTabs>
  );

  const renderList = (
    <Scrollbar>
      <Box component="ul">
        {notifications?.map((notification) => (
          <Box component="li" key={notification.id} sx={{ display: 'flex' }}>
            <NotificationItem notification={notification} />
          </Box>
        ))}
      </Box>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={drawer.onTrue}
        sx={{
          mr: totalUnRead ? '9px' : 0,

          ...sx
        }}
        {...other}
      >
        <Badge
          badgeContent={totalUnRead}
          sx={{
            width: 18,
            height: 18,

            '.MuiBadge-badge': {
              color: 'white',
              backgroundColor: 'var(--layout-toolbar-counter-color)'
            },
          }}
        >
          <SvgIcon
            viewBox="0 0 20 20"
            sx={{
              width: 20,
              height: 20,
              color: 'var(--layout-toolbar-icon-color)',
              fill: 'none',
            }}
          >
            <g clipPath="url(#clip0_4017_15189)">
              <path
                d="M8.55664 18.25C8.70293 18.5033 8.91332 18.7137 9.16668 18.86C9.42003 19.0063 9.70743 19.0833 9.99997 19.0833C10.2925 19.0833 10.5799 19.0063 10.8333 18.86C11.0866 18.7137 11.297 18.5033 11.4433 18.25"
                stroke="var(--layout-toolbar-icon-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path
                d="M2.71821 13.5215C2.60935 13.6408 2.53751 13.7892 2.51143 13.9486C2.48534 14.108 2.50615 14.2715 2.5713 14.4193C2.63646 14.5671 2.74316 14.6928 2.87843 14.7811C3.01369 14.8693 3.1717 14.9164 3.33321 14.9165H16.6665C16.828 14.9166 16.9861 14.8697 17.1214 14.7816C17.2568 14.6935 17.3636 14.568 17.429 14.4203C17.4943 14.2726 17.5153 14.1091 17.4894 13.9497C17.4635 13.7902 17.3919 13.6418 17.2832 13.5223C16.1749 12.3798 14.9999 11.1657 14.9999 7.4165C14.9999 6.09042 14.4731 4.81865 13.5354 3.88097C12.5977 2.94329 11.326 2.4165 9.99988 2.4165C8.6738 2.4165 7.40203 2.94329 6.46435 3.88097C5.52666 4.81865 4.99988 6.09042 4.99988 7.4165C4.99988 11.1657 3.82405 12.3798 2.71821 13.5215Z"
                stroke="var(--layout-toolbar-icon-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_4017_15189">
                <rect width="20" height="20" fill="white" transform="translate(0 0.75)"/>
              </clipPath>
            </defs>
          </SvgIcon>
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{ backdrop: { invisible: true } }}
        PaperProps={{ sx: { width: 1, maxWidth: 420 } }}
      >
        {renderHead}

        {renderTabs}

        {renderList}

        <Box sx={{ p: 1 }}>
          <Button fullWidth size="large">
            View all
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
