import type { IconButtonProps } from '@mui/material/IconButton';

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import Popover from '@mui/material/Popover';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { usePopover } from 'src/components/custom-popover';
import { Iconify, ArrowDownIcon } from 'src/components/iconify';

// ----------------------------------------------------------------------

export type LanguagePopoverProps = IconButtonProps & {
  data?: {
    value: string,
    label: string,
    countryCode: string,
  }[],
};

export function LanguagePopover({ data = [], sx, ...other }: LanguagePopoverProps) {
  const popover = usePopover();

  const { t, i18n } = useTranslation();

  const changeLanguageAction = useCallback((language: string): void => {
    if (i18n.language !== language) {
      localStorage.setItem('preferred-language', language);
      i18n.changeLanguage(language);
      popover.onClose();
    }
  }, [i18n, popover]);

  return (
    <>
      <Button
        sx={{
          py: 1,
          px: 2,
          borderRadius: 0,
          color: popover.open ? 'text.primary' : 'text.secondary',
          backgroundColor: popover.open ? 'var(--palette-action-hover)' : 'transparent',

          '&:hover': {
            color: 'text.primary',
          },

          'path': {
            stroke: popover.open ? 'var(--palette-text-primary)' : 'var(--palette-text-secondary)',
          },

          '&:hover path': {
            stroke: 'var(--palette-text-primary)',
          },
        }}
        onClick={popover.onOpen}
      >
        <Stack sx={{ width: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
            <SvgIcon
              viewBox="0 0 20 20"
              sx={{
                width: 20,
                height: 20,
                fill: 'none',
              }}
            >
              <g clipPath="url(#clip0_4042_40241)">
                <path
                  d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z"
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M9.99984 1.6665C7.86003 3.9133 6.6665 6.89712 6.6665 9.99984C6.6665 13.1026 7.86003 16.0864 9.99984 18.3332C12.1396 16.0864 13.3332 13.1026 13.3332 9.99984C13.3332 6.89712 12.1396 3.9133 9.99984 1.6665Z"
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1.6665 10H18.3332" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_4042_40241">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </SvgIcon>

            <Typography
              variant="body2"
              sx={{ lineHeight: '22px' }}
            >
              {t('language')}
            </Typography>
          </Stack>

          <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, lineHeight: '22px', textTransform: 'uppercase' }}
            >
              {i18n.language}
            </Typography>

            <ArrowDownIcon sx={{ width: 16, height: 16, transform: 'rotate(-90deg)' }} />
          </Stack>
        </Stack>
      </Button>

      <Popover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              minWidth: 192,
              width: 'auto',
              ml: '5px',
              p: 0,
              border: '1px solid var(--layout-nav-border-color)',
              borderRadius: 1,
              backgroundColor: 'background.paper',
              zIndex: 1200,
            },
          },
        }}
      >
        <Stack sx={{ p: 2 }}>
          <MenuList sx={{ display: 'flex', flexDirection: 'column', gap: 1, '.MuiMenuItem-root': { mb: '0 !important', justifyContent: 'space-between' } }}>
            {data?.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => changeLanguageAction(option.value)}
              >
                <Typography
                  variant="body2"
                  sx={{ lineHeight: '22px' }}
                >
                  {option.label}
                </Typography>

                {
                  option.value === i18n.language &&
                  <Iconify
                    width={20}
                    icon="eva:checkmark-fill"
                    sx={{ color: 'var(--palette-primary-main)' }}
                  />
                }
              </MenuItem>
            ))}
          </MenuList>
        </Stack>
      </Popover>
    </>
  );
}
