import type { BoxProps } from '@mui/material/Box';

import React from 'react';

import Box from '@mui/material/Box';
import Portal from '@mui/material/Portal';
import { useTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import { varAlpha } from 'src/theme/styles';

// ----------------------------------------------------------------------

type Props = BoxProps & {
  portal?: boolean;
};

export function LoadingScreen({ portal, sx, ...other }: Props) {
  const theme = useTheme();

  const content = (
    <Box
      sx={{
        px: 5,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: () => varAlpha(theme.vars.palette.grey['500Channel'], 0.04),
        ...(portal && {
          position: 'absolute',
          zIndex: 9998,
          bgcolor: () => varAlpha(theme.vars.palette.grey['500Channel'], 0.32),
        }),
        ...sx,
      }}
      {...other}
    >
      {
        portal
          ? <CircularProgress color="inherit" sx={{ width: 1, maxWidth: 60 }} />
          : <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
      }
    </Box>
  );

  if (portal) {
    return <Portal>{content}</Portal>;
  }

  return content;
}
