import { Navigate } from 'src/routes/components';
import { Outlet } from 'src/routes/components/outlet';

import { ServerErrorView } from 'src/sections/error';

import { useHasAccess } from 'src/auth/hooks';
import { AuthGuardWrapper } from 'src/auth/guard';

// ----------------------------------------------------------------------

export function VehicleCardRoutes() {
  const { redirectPath } = useHasAccess();

  return [
    {
      path: 'vehicle-card',
      element: <AuthGuardWrapper><Outlet /></AuthGuardWrapper>,
      errorElement: <ServerErrorView />,
      children: [
        {
          index: true,
          element: <Navigate to={redirectPath} replace />,
        },
        {
          path: ':uuid',
          lazy: () => import('src/pages/vehicle-card'),
        },
      ],
    },
  ];
}
