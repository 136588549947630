import { Navigate } from 'src/routes/components';
import { Outlet } from 'src/routes/components/outlet';

import { ServerErrorView } from 'src/sections/error';

import { useHasAccess } from 'src/auth/hooks';
import { RoleRouteGuard, AuthGuardWrapper } from 'src/auth/guard';

import { optionsLoader } from '../utils';

// ----------------------------------------------------------------------

export function AdministrationRoutes() {
  const { hasAccess, redirectPath } = useHasAccess('super_admin');

  return [
    {
      path: 'administration',
      element: <AuthGuardWrapper><Outlet /></AuthGuardWrapper>,
      errorElement: <ServerErrorView />,
      children: [
        {
          index: true,
          element: (
            <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
              <Navigate to="/administration/entities" replace />
            </RoleRouteGuard>
          ),
        },
        {
          path: 'entities',
          children: [
            {
              index: true,
              lazy: () => import('src/pages/administration/entities'),
            },
            {
              path: 'view',
              children: [
                {
                  index: true,
                  element: (
                    <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                      <Navigate to="/administration/entities" replace />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: 'user',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/user'),
                      loader: () => optionsLoader(hasAccess, ['company', 'role']),
                      shouldRevalidate: () => false,
                    },
                  ],
                },
                {
                  path: 'role',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/role'),
                    },
                  ],
                },
                {
                  path: 'company',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/company'),
                      loader: () => optionsLoader(hasAccess, ['company']),
                      shouldRevalidate: () => false,
                    },
                  ],
                },
                {
                  path: 'claim',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/claim'),
                    },
                  ],
                },
                {
                  path: 'event',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/event'),
                    },
                  ],
                },
                {
                  path: 'event-type',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/event-type'),
                    },
                  ],
                },
                {
                  path: 'access-request',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/access-request'),
                    },
                  ],
                },
                {
                  path: 'configurator-vehicle',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/configurator-vehicle'),
                      loader: () => optionsLoader(hasAccess, ['configuratorVehicle']),
                      shouldRevalidate: () => false,
                    },
                  ],
                },
                {
                  path: 'fault-entities',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/fault-entity'),
                    },
                  ],
                },
                {
                  path: 'http-log',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/http-log'),
                    },
                  ],
                },
                {
                  path: 'inventory-vehicle',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/inventory-vehicle'),
                    },
                  ],
                },
                {
                  path: 'labor',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/labor'),
                      loader: () => optionsLoader(hasAccess, ['configuratorVehicle']),
                      shouldRevalidate: () => false,
                    },
                  ],
                },
                {
                  path: 'modification',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/modification'),
                      loader: () => optionsLoader(hasAccess, ['configuratorVehicle']),
                      shouldRevalidate: () => false,
                    },
                  ],
                },
                {
                  path: 'service-interval',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/service-interval'),
                      loader: () => optionsLoader(hasAccess, ['modification']),
                      shouldRevalidate: () => false,
                    },
                  ],
                },
                {
                  path: 'spare-part',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/spare-part'),
                    },
                  ],
                },
                {
                  path: 'vehicle-options',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/vehicle-option'),
                      loader: () => optionsLoader(hasAccess, ['modification']),
                      shouldRevalidate: () => false,
                    },
                  ],
                },
                {
                  path: 'workshop',
                  children: [
                    {
                      index: true,
                      element: (
                        <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                          <Navigate to="/administration/entities" replace />
                        </RoleRouteGuard>
                      ),
                    },
                    {
                      path: ':uuid',
                      lazy: () => import('src/pages/administration/entities/view/workshop'),
                      loader: () => optionsLoader(hasAccess, ['modification']),
                      shouldRevalidate: () => false,
                    },
                  ],
                },
              ],
            },
            {
              path: 'new',
              children: [
                {
                  index: true,
                  element: (
                    <RoleRouteGuard requiredAccess="super_admin" navigateTo={redirectPath}>
                      <Navigate to="/administration/entities" replace />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: 'user',
                  lazy: () => import('src/pages/administration/entities/new/user'),
                  loader: () => optionsLoader(hasAccess, ['company', 'role']),
                  shouldRevalidate: () => false,
                },
                {
                  path: 'role',
                  lazy: () => import('src/pages/administration/entities/new/role'),
                },
                {
                  path: 'company',
                  lazy: () => import('src/pages/administration/entities/new/company'),
                  loader: () => optionsLoader(hasAccess, ['company']),
                  shouldRevalidate: () => false,
                },
                {
                  path: 'claim',
                  lazy: () => import('src/pages/administration/entities/new/claim'),
                },
                {
                  path: 'event',
                  lazy: () => import('src/pages/administration/entities/new/event'),
                },
                {
                  path: 'event-type',
                  lazy: () => import('src/pages/administration/entities/new/event-type'),
                },
                {
                  path: 'access-request',
                  lazy: () => import('src/pages/administration/entities/new/access-request'),
                },
                {
                  path: 'configurator-vehicle',
                  lazy: () => import('src/pages/administration/entities/new/configurator-vehicle'),
                  loader: () => optionsLoader(hasAccess, ['configuratorVehicle']),
                  shouldRevalidate: () => false,
                },
                {
                  path: 'fault-entities',
                  lazy: () => import('src/pages/administration/entities/new/fault-entity'),
                },
                {
                  path: 'labor',
                  lazy: () => import('src/pages/administration/entities/new/labor'),
                  loader: () => optionsLoader(hasAccess, ['configuratorVehicle']),
                  shouldRevalidate: () => false,
                },
                {
                  path: 'modification',
                  lazy: () => import('src/pages/administration/entities/new/modification'),
                  loader: () => optionsLoader(hasAccess, ['configuratorVehicle']),
                  shouldRevalidate: () => false,
                },
                {
                  path: 'service-interval',
                  lazy: () => import('src/pages/administration/entities/new/service-interval'),
                  loader: () => optionsLoader(hasAccess, ['modification']),
                  shouldRevalidate: () => false,
                },
                {
                  path: 'spare-part',
                  lazy: () => import('src/pages/administration/entities/new/spare-part'),
                },
                {
                  path: 'vehicle-options',
                  lazy: () => import('src/pages/administration/entities/new/vehicle-option'),
                  loader: () => optionsLoader(hasAccess, ['modification']),
                  shouldRevalidate: () => false,
                },
                {
                  path: 'workshop',
                  lazy: () => import('src/pages/administration/entities/new/workshop'),
                  loader: () => optionsLoader(hasAccess, ['company']),
                  shouldRevalidate: () => false,
                },
              ],
            },
          ],
        },
        {
          path: 'storage',
          lazy: () => import('src/pages/administration/storage'),
        },
        {
          path: 'smtp',
          lazy: () => import('src/pages/administration/smtp'),
        },
      ],
    },
  ];
}
