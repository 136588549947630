import type { ReactNode } from 'react';
import type { PageSettings, PagedAsyncIterableIterator } from '@azure/core-paging';
import type { ConfigurationSetting, ListConfigurationSettingPage } from '@azure/app-configuration';

import { cloneDeep } from 'lodash';
import { AppConfigurationClient } from '@azure/app-configuration';
import { useState, useEffect, useCallback, createContext } from 'react';

import { paths } from 'src/routes/paths';

import { SplashScreen } from 'src/components/loading-screen';
import { MotionLazy } from 'src/components/animate/motion-lazy';

import { merge } from '../utils/helper';
import { CONFIG } from '../config-global';
import packageJson from '../../package.json';
import { useHasAccess } from '../auth/hooks';
import { getStorage, setStorage } from '../hooks/use-local-storage';

const client = new AppConfigurationClient(CONFIG.configConnString);

const CONFIG_KEY_PATTERN = 'carxo.frontend.*';
const CONFIG_LABEL_DEFAULT = 'default';
const CONFIG_LABEL_DOMAIN = window.location.hostname;
const CONFIG_LOCAL_STORAGE_KEY = 'domain-config';

type KeyConfig = {
  carxo: {
    frontend: {
      site: {
        name: string;
        serverUrl: string;
        assetUrl: string;
        basePath: string;
        version: string;
      };
      auth: {
        skip: boolean;
        login: string;
        signIn: string;
      };
      sanctum: {
        apiUrl: string;
        csrfCookieRoute: string;
        signInRoute: string;
        signOutRoute: string;
        userObjectRoute: string;
      };
      hotjar: {
        id: number,
        sv: number,
      },
      sentry: {
        dsn: string,
      },
    };
  };
};

const DEFAULT_CONFIG: KeyConfig = {
  carxo: {
    frontend: {
      site: {
        name: 'CarXO dev',
        serverUrl: window.location.origin,
        assetUrl: window.location.origin,
        basePath: window.location.origin,
        version: packageJson.version,
      },
      auth: {
        skip: false,
        login: paths.auth.login,
        signIn: paths.auth.signIn,
      },
      sanctum: {
        apiUrl: '',
        csrfCookieRoute: '',
        signInRoute: '',
        signOutRoute: '',
        userObjectRoute: '',
      },
      hotjar: {
        id: 0,
        sv: 6,
      },
      sentry: {
        dsn: '',
      },
    },
  },
};

const SENSITIVE_KEYS = [
  'carxo.frontend.auth.skip',
  'carxo.frontend.hotjar.id',
  'carxo.frontend.sentry.dsn',
];

export const ConfigContext = createContext(DEFAULT_CONFIG);

const expand = (str: string, defaultVal: any): any =>
  str.split('.').reduceRight(
    (acc: any, currentVal: string) => ({
      [currentVal]: acc,
    }),
    defaultVal
  );

function getSettingValue(setting: ConfigurationSetting<string>): string | boolean | number | Array<any> {
  switch (setting.contentType) {
    case 'int':
      if (!setting.value) {
        return 0;
      }

      return parseInt(setting.value, 10);
    case 'float':
      if (!setting.value) {
        return 0;
      }

      return parseFloat(setting.value);
    case 'boolean':
      return setting.value === 'true';
    case 'array':
      if (!setting.value) {
        return [];
      }

      return JSON.parse(setting.value);
    default:
      if (!setting.value) {
        return '';
      }

      return setting.value;
  }
}

function getObjectKeys(object: KeyConfig): string[] {
  return Object.entries(object.carxo.frontend).reduce((array: string[], [sectionKey, sectionValue]) => {
    Object.keys(sectionValue).forEach((configKey) => {
      const key = `carxo.frontend.${sectionKey}.${configKey}`;

      if (!SENSITIVE_KEYS.includes(key)) {
        array.push(key);
      }
    });

    return array;
  }, []);
}

function configStructureUpToDate(object: KeyConfig): boolean {
  const baseKeys = new Set(getObjectKeys(DEFAULT_CONFIG));
  const objectKeys = new Set(getObjectKeys(object));

  return baseKeys.size === objectKeys.size && [...Array.from(baseKeys)].every((key) => objectKeys.has(key));
}

function excludeSensitiveKeys(object: KeyConfig): KeyConfig {
  const safeObject = cloneDeep(object);

  SENSITIVE_KEYS.forEach((sensitiveKey) => {
    const [organization, location, section, config] = sensitiveKey.split('.');

    // @ts-ignore
    delete safeObject[organization][location][section][config];
  });

  return safeObject;
}

async function configIterator(
  config: KeyConfig,
  keyClient: PagedAsyncIterableIterator<
    ConfigurationSetting<string>,
    ListConfigurationSettingPage,
    PageSettings
  >,
  continuationToken: string | undefined = undefined
): Promise<void> {
  const iterator = keyClient.byPage({ continuationToken });
  const response = await iterator.next();

  let _token: string | undefined;
  if (!response.done) {
    response.value.items.forEach((setting) => {
      merge(config, expand(setting.key, getSettingValue(setting)));
    });
    _token = response.value.continuationToken;
  }

  if (_token) {
    await configIterator(config, keyClient, response.value.continuationToken);
  }
}

async function getConfig(): Promise<KeyConfig> {
  if (!window.crypto.subtle) {
    return excludeSensitiveKeys(DEFAULT_CONFIG);
  }

  let configFromStorage: KeyConfig | undefined = getStorage(CONFIG_LOCAL_STORAGE_KEY);
  let _config: KeyConfig = DEFAULT_CONFIG;

  if (configFromStorage && configStructureUpToDate(configFromStorage)) {
    _config = configFromStorage;
  } else {
    configFromStorage = undefined;
  }

  if (!configFromStorage) {
    const defaultKeys = client.listConfigurationSettings({
      keyFilter: CONFIG_KEY_PATTERN,
      labelFilter: CONFIG_LABEL_DEFAULT,
    });
    const domainKeys = client.listConfigurationSettings({
      keyFilter: CONFIG_KEY_PATTERN,
      labelFilter: CONFIG_LABEL_DOMAIN,
    });

    await configIterator(_config, defaultKeys);
    await configIterator(_config, domainKeys);
  }

  if (configFromStorage) {
    const sensitiveDefaultKeys = client.listConfigurationSettings({
      keyFilter: SENSITIVE_KEYS.join(','),
      labelFilter: CONFIG_LABEL_DEFAULT,
    });
    const sensitiveDomainKeys = client.listConfigurationSettings({
      keyFilter: SENSITIVE_KEYS.join(','),
      labelFilter: CONFIG_LABEL_DOMAIN,
    });

    await configIterator(_config, sensitiveDefaultKeys);
    await configIterator(_config, sensitiveDomainKeys);
  }

  setStorage(CONFIG_LOCAL_STORAGE_KEY, excludeSensitiveKeys(_config));

  return _config;
}

export function ConfigInjector({ children }: { children: ReactNode }) {
  const [config, setConfig] = useState<KeyConfig>();
  const [loaded, setLoaded] = useState(false);

  const updateConfig = useCallback(async () => {
    const result = await getConfig();

    setConfig(result);
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!config) {
      updateConfig();
    }
  }, [config, updateConfig]);

  return (
    <>
      {
        (!loaded || !config) &&
        <MotionLazy>
          <SplashScreen noTheme />
        </MotionLazy>
      }
      {
        loaded && config &&
        <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
      }
    </>
  );
}
