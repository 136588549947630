import type { AxiosResponse } from 'axios';
import type { CacheRequestConfig } from 'axios-cache-interceptor';

import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

import { paths } from 'src/routes/paths';

import type { TableRowsProps, ListRequestProps } from './types';

// ----------------------------------------------------------------------

const instance = axios.create({
  withCredentials: false,
});
const instanceWithCache = setupCache(instance, {
  methods: [],
});

// ----------------------------------------------------------------------

type MakeRequestProps<Data> = {
  url: string,
} & ({
  type: 'get' | 'delete',
  data?: never,
} | {
  type: 'post' | 'patch' | 'formData',
  data: Data,
});

export function makeRequest<Data, Response>(
  {
    type,
    url,
    data,
  }: MakeRequestProps<Data>, options?: CacheRequestConfig
): Promise<AxiosResponse<Response, any>> {
  let request = null;

  switch (type) {
    case 'get':
      request = instanceWithCache.get<Response>(url, options);
      break;
    case 'post':
      request = instanceWithCache.post<Response>(url, data, options);
      break;
    case 'patch':
      request = instanceWithCache.patch<Response>(url, data, options);
      break;
    case 'delete':
      request = instanceWithCache.delete<Response>(url, options);
      break;
    case 'formData':
      request = instanceWithCache.post<Response>(url, data, { headers: { 'Content-Type': 'multipart/form-data' } });
      break;
    default:
      throw new Error(`Unknown request type: ${type}`);
  }

  if (window.location.protocol === 'http:') {
    return request;
  }

  return request.catch(({ message, response: { status, data: { message: dataMessage } } }) => {
    if (status === 401) {
      window.location.href = paths.auth.login;
    }

    throw new Error(dataMessage ?? message);
  });
}

// ----------------------------------------------------------------------

type MakeListRequestProps = {
  url: string,
  paginationProps: ListRequestProps,
};

export function makeListRequest<Entity>(
  {
    url,
    paginationProps: { prev: paginationPrev, next: paginationNext, ...rest },
  }: MakeListRequestProps,
  options?: CacheRequestConfig,
): Promise<TableRowsProps<Entity>> {
  let requestUrl = '';

  if (paginationPrev || paginationNext) {
    const paginationString = paginationPrev ?? paginationNext;

    requestUrl = `${url}${paginationString!.substring(1)}`;
  } else {
    requestUrl = Object.entries(rest).reduce((string: string, [key, value]) => {
      if (string.includes('?')) {
        return `${string}&${key}=${key === 'search' ? encodeURIComponent(value) : value}`;
      }

      return `${string}?${key}=${key === 'search' ? encodeURIComponent(value) : value}`;
    }, url);
  }

  const request = instanceWithCache
    .get<{
      data: Entity[],
      links: { prev: string | null, next: string | null },
      meta: { total: number }
    }>(requestUrl, options)
    .then((
      {
        data: {
          data,
          links: { prev, next },
          meta: { total }
        }
      }
    ) => ({ data, prev, next, total }));

  if (window.location.protocol === 'http:') {
    return request;
  }

  return request.catch(({ message, response: { status, data: { message: dataMessage } } }) => {
    if (status === 401) {
      window.location.href = paths.auth.login;
    }

    throw new Error(dataMessage ?? message);
  });
}
