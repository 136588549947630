import { m } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';

import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { varAlpha } from 'src/theme/styles';
import { MainLayout } from 'src/layouts/core';
import { Main, CompactContent } from 'src/layouts/simple/main';
import { LayoutSection } from 'src/layouts/core/layout-section';
import { ServerErrorIllustration } from 'src/assets/illustrations';

import { varBounce, MotionContainer } from 'src/components/animate';

// ----------------------------------------------------------------------

type Props = {
  excludeWrapper?: boolean,
};

export function ServerErrorView({ excludeWrapper }: Props) {
  const { t } = useTranslation();

  const theme = useTheme();

  const error = useRouteError() as any;

  const layout = (
    <LayoutSection
      headerSection={null}
      footerSection={null}
      cssVars={{
        '--layout-simple-content-compact-width': '448px',
      }}
      sx={{ bgcolor: () => varAlpha(theme.vars.palette.grey['500Channel'], 0.04) }}
    >
      <Main>
        <CompactContent>
          <Container component={MotionContainer}>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" sx={{ mb: 2 }}>
                {t('internalServerError')}
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography sx={{ color: 'text.secondary' }}>
                {error?.message}
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <ServerErrorIllustration sx={{ my: { xs: 5, sm: 10 } }} />
            </m.div>

            <Button onClick={() => window.location.reload()} size="large" variant="contained">
              {t('reload')}
            </Button>
          </Container>
        </CompactContent>
      </Main>
    </LayoutSection>
  );

  if (excludeWrapper) {
    return <>{layout}</>
  }

  return (
    <MainLayout>
      {layout}
    </MainLayout>
  );
}
