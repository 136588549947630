import { Navigate } from 'src/routes/components';
import { Outlet } from 'src/routes/components/outlet';

import { ServerErrorView } from 'src/sections/error';

import { useHasAccess } from 'src/auth/hooks';
import { RoleRouteGuard, AuthGuardWrapper } from 'src/auth/guard';

import { optionsLoader } from '../utils';

// ----------------------------------------------------------------------

export function ManagementRoutes() {
  const { hasAccess, redirectPath } = useHasAccess(['management', 'super_admin'], 'or');

  return [
    {
      path: 'management',
      element: <AuthGuardWrapper><Outlet /></AuthGuardWrapper>,
      errorElement: <ServerErrorView />,
      children: [
        {
          index: true,
          element: (
            <RoleRouteGuard
              requiredAccess={['management', 'super_admin']}
              operator="or"
              navigateTo={redirectPath}
            >
              <Navigate to="/management/access-requests" replace />
            </RoleRouteGuard>
          ),
        },
        {
          path: 'access-requests',
          children: [
            {
              index: true,
              lazy: () => import('src/pages/management/access-requests'),
            },
            {
              path: 'view',
              children: [
                {
                  index: true,
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['management', 'super_admin']}
                      operator="or"
                      navigateTo={redirectPath}
                    >
                      <Navigate to="/management/access-requests" replace />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: ':uuid',
                  lazy: () => import('src/pages/management/access-requests/view'),
                },
              ],
            },
          ],
        },
        {
          path: 'users',
          children: [
            {
              index: true,
              lazy: () => import('src/pages/management/users'),
            },
            {
              path: 'view',
              children: [
                {
                  index: true,
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['management', 'super_admin']}
                      operator="or"
                      navigateTo={redirectPath}
                    >
                      <Navigate to="/management/users" replace />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: ':uuid',
                  lazy: () => import('src/pages/management/users/view'),
                  loader: () => optionsLoader(hasAccess, ['role']),
                  shouldRevalidate: () => false,
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}
