import type { CartItem } from 'src/types/cart';

import { create } from 'zustand';

import { convertToCurrency } from 'src/utils/format-number';
import { getCartItems, syncCartItems, addOrUpdateCartItem } from 'src/utils/requests/cart';

import { toast } from 'src/components/snackbar';

type CartState = {
  initialized: boolean,
  itemPending: boolean,
  itemsPending: boolean,
  open: boolean,
  items: CartItem[],
  total: number,
  openCart: () => void,
  closeCart: () => void,
  updateCart: () => Promise<boolean>,
  setItems: () => Promise<boolean>,
  addItem: (code: string, quantity: number) => Promise<boolean>,
  updateItem: (code: string, quantity: number) => Promise<boolean>,
  removeItem: (code: string) => Promise<boolean>,
  syncItems: (responseItems: CartItem[]) => CartItem[],
  syncCart: () => Promise<boolean>,
  getTotal: (items: CartItem[]) => number,
};

export const useCartStore = create<CartState>()((set, get) => ({
  initialized: false,
  itemPending: false,
  itemsPending: false,
  open: false,
  items: [],
  total: 0,
  openCart: () => set({ open: true }),
  closeCart: () => set({ open: false }),
  updateCart: async () => {
    set({ itemsPending: true });

    const response = await getCartItems().catch(({ message }) => {
      toast.error(message);
    });

    set({
      itemsPending: false,
      ...(response && {
        initialized: true,
        items: get().syncItems(response.items),
        total: get().getTotal(response.items),
      }),
    });

    return !!response;
  },
  setItems: async () => {
    let success = true;

    if (!get().initialized) {
      success = await get().updateCart();
    }

    return success;
  },
  addItem: async (code: string, quantity: number) => {
    const itemInCart = get().items.find((item) => item.code === code);

    set({ itemsPending: true });

    const response = await addOrUpdateCartItem(code, itemInCart ? quantity + itemInCart.quantity : quantity).catch(({ message }) => {
      toast.error(message);
    });

    set({
      itemsPending: false,
      ...(response && {
        items: get().syncItems(response.items),
        total: get().getTotal(response.items),
      }),
    });

    return !!response;
  },
  updateItem: async (code: string, quantity: number) => {
    if (quantity < 1) {
      const success = await get().removeItem(code);

      return success;
    }

    set({ itemPending: true });

    const response = await addOrUpdateCartItem(code, quantity).catch(({ message }) => {
      toast.error(message);
    });

    set({
      itemPending: false,
      ...(response && {
        items: get().syncItems(response.items),
        total: get().getTotal(response.items),
      }),
    });

    return !!response;
  },
  removeItem: async (code: string) => {
    set({ itemsPending: true });

    const response = await addOrUpdateCartItem(code, 0).catch(({ message }) => {
      toast.error(message);
    });

    set({
      itemsPending: false,
      ...(response && {
        items: get().syncItems(response.items),
        total: get().getTotal(response.items),
      }),
    });

    return !!response;
  },
  syncItems: (responseItems: CartItem[]) => {
    const oldItems = get().items;

    if (!oldItems.length || !responseItems.length) {
      return responseItems;
    }

    const difference = responseItems.filter(
      ({ code: responseItemCode }) => !oldItems.find(
        ({ code: oldItemCode }) => oldItemCode === responseItemCode
      )
    );

    const newItems = oldItems.reduce((map: CartItem[], { code }: CartItem) => {
      const newItem = responseItems.find(({ code: responseItemCode }) => responseItemCode === code);

      if (newItem) {
        map.push(newItem);
      }

      return map;
    }, []);

    if (difference.length > 0) {
      newItems.push(...difference);
    }

    return newItems;
  },
  syncCart: async () => {
    set({ itemsPending: true });

    const response = await syncCartItems(get().items).catch(({ message }) => {
      toast.error(message);
    });

    set({
      itemsPending: false,
      ...(response && {
        items: get().syncItems(response.items),
        total: get().getTotal(response.items),
      }),
    });

    return !!response;
  },
  getTotal: (items: CartItem[]) => items.reduce((map, item) => {
    map += convertToCurrency(item.lineAmount, 'number');
    return map;
  }, 0),
}));
