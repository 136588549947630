import type { User } from 'src/types/auth/sanctum';

import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSanctum } from 'react-sanctum';
import { useTranslation } from 'react-i18next';

import { allLangs } from 'src/locales/all-langs';

import { useSettingsContext } from '../settings';

export function FeedbackWindow() {
  const settings = useSettingsContext();

  const { i18n } = useTranslation();

  const { user } = useSanctum<User>();

  useEffect(() => {
    if (!user) {
      return;
    }

    const win = window as any;

    win.Featurebase = function () {
      // eslint-disable-next-line prefer-rest-params
      (win.Featurebase.q = ([] as IArguments[])).push(arguments);
    };

    win.Featurebase('initialize_feedback_widget', {
      organization: 'importerbox',
      theme: settings.colorScheme,
      placement: 'right',
      name: user.name,
      email: `${user.uuid}@feedback.com`,
      locale: allLangs.find((language) => language.value === i18n.language)?.adapterLocale ?? 'en',
    });
  }, [user, settings.colorScheme, i18n.language]);

  if (!user) {
    return false;
  }

  return (
    <Helmet>
      <script src="https://do.featurebase.app/js/sdk.js" id="featurebase-sdk" />
    </Helmet>
  );
}
