import type { VINResult, SearchResultData, GlobalSearchResult, RegistrationNumberResult } from 'src/types/search';

import { useDebounce } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter, useParams, usePathname } from 'src/routes/hooks';

import { getGlobalSearchResults } from 'src/utils/requests/search';

import { Autocomplete } from 'src/components/fields';
import { SearchAdornment } from 'src/components/custom-adornments';

// ----------------------------------------------------------------------

export function Searchbar() {
  const { t } = useTranslation();

  const router = useRouter();
  const { uuid } = useParams();
  const pathname = usePathname();

  const [results, setResults] = useState<GlobalSearchResult[]>([]);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 750);
  const [selectedResult, setSelectedResult] = useState<GlobalSearchResult | null>(null);
  const [loading, setLoading] = useState(false);

  const updateSearchResults = useCallback(async () => {
    if (debouncedQuery.length === 0) {
      setResults([]);
      return;
    }

    setLoading(true);
    const searchResponse: SearchResultData = await getGlobalSearchResults(debouncedQuery);
    const searchResults: GlobalSearchResult[] = Array.isArray(searchResponse) ? searchResponse : Object.entries(searchResponse).reduce((map: GlobalSearchResult[], [key, value]) => {
      switch (key) {
        case 'vins': {
          const vinResults: GlobalSearchResult[] = (value as VINResult[]).map((searchResult: VINResult): GlobalSearchResult => ({ uuid: searchResult.uuid, type: t('vin'), value: searchResult.vin }));
          map.push(...vinResults);
          break;
        }
        case 'registrationNumbers': {
          const registrationNumberResults: GlobalSearchResult[] = (value as RegistrationNumberResult[]).map((searchResult: RegistrationNumberResult): GlobalSearchResult => ({ uuid: searchResult.uuid, type: t('registrationNumber'), value: searchResult.registrationNumber }));
          map.push(...registrationNumberResults);
          break;
        }
        default:
          break;
      }

      return map;
    }, []);
    setResults(searchResults);
    setLoading(false);
  }, [debouncedQuery, t]);

  useEffect(() => {
    if (!selectedResult) {
      return;
    }

    if (uuid !== selectedResult.uuid || !pathname.includes('/vehicle-card/')) {
      router.push(paths.vehicleCard(selectedResult.uuid));
      setQuery('');
    }

    setSelectedResult(null);
  }, [selectedResult, uuid, pathname, router]);

  useEffect(() => {
    updateSearchResults();
  }, [debouncedQuery, updateSearchResults]);

  return (
    <Autocomplete
      disablePortal
      inputProps={{
        placeholder: t('search'),
        value: query,
        onChange: (e) => setQuery(e.target.value),
        startAdornment: <SearchAdornment sx={{ mr: 3.5, 'svg': { width: 16, height: 16, color: 'var(--layout-toolbar-search-icon-color)' } }} />,
        endAdornment: <></>,
      }}
      open={(state) => state && query.length > 0}
      clearOnBlur={false}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.value}
      options={results}
      inputValue={query}
      loading={loading}
      groupBy={(option) => option.type}
      value={selectedResult}
      onChange={(event: any, newValue: GlobalSearchResult | null) => setSelectedResult(newValue)}
      sx={{
        maxWidth: 320,

        '.MuiOutlinedInput-root': {
          height: 40,
          padding: 1.5,
          backgroundColor: 'var(--layout-toolbar-search-color)',
        },

        'input.MuiAutocomplete-input': {
          color: 'text.primary',
          fontSize: 14,
        },

        'fieldset': {
          borderColor: 'var(--layout-nav-border-color)',
        },
      }}
      noOptionsText={t('noResults')}
    />
  );
}
