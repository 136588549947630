import type { IconButtonProps } from '@mui/material/IconButton';

import { m } from 'framer-motion';

import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';

// ----------------------------------------------------------------------

export type AccountButtonProps = IconButtonProps & {
  open: boolean,
};

export function AccountButton({ open, sx, ...other }: AccountButtonProps) {
  return (
    <IconButton
      disableRipple
      component={m.button}
      sx={{
        p: 0,
        gap: 1,

        ...sx,

        ml: 0,
      }}
      {...other}
    >
      <SvgIcon
        viewBox="0 0 16 16"
        sx={{
          width: 16,
          height: 16,
          fill: 'none',

          ...(open && {
            transform: 'rotate(180deg)',
          }),
        }}
      >
        <path d="M4 6L8 10L12 6" stroke="var(--layout-toolbar-account-arrow-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </SvgIcon>

      <SvgIcon
        viewBox="0 0 20 20"
        sx={{
          width: 20,
          height: 20,
          p: 0.75,
          color: 'var(--layout-toolbar-active-icon-color)',
          backgroundColor: 'var(--layout-toolbar-active-bg)',
          borderRadius: '50%',
          fill: 'none',
          boxSizing: 'content-box',
        }}
      >
        <path
          d="M9.99992 10.8333C12.3011 10.8333 14.1666 8.96785 14.1666 6.66667C14.1666 4.36548 12.3011 2.5 9.99992 2.5C7.69873 2.5 5.83325 4.36548 5.83325 6.66667C5.83325 8.96785 7.69873 10.8333 9.99992 10.8333Z"
          stroke="var(--layout-toolbar-active-icon-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path
          d="M16.6666 17.5002C16.6666 15.7321 15.9642 14.0364 14.714 12.7861C13.4637 11.5359 11.768 10.8335 9.99992 10.8335C8.23181 10.8335 6.53612 11.5359 5.28587 12.7861C4.03563 14.0364 3.33325 15.7321 3.33325 17.5002"
          stroke="var(--layout-toolbar-active-icon-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </SvgIcon>
    </IconButton>
  );
}
