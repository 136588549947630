import { RouterProvider } from 'react-router-dom';

import { SplashScreen } from 'src/components/loading-screen';

import { Router } from '../sections';

// ----------------------------------------------------------------------

export function RouterProviderWrapper() {
  return (
    <RouterProvider router={Router()} fallbackElement={<SplashScreen />} />
  );
}
