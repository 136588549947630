import type { Role, Company, EventType, Modification, ConfiguratorVehicle } from 'src/types/global';

import { makeRequest } from './utils';

// ----------------------------------------------------------------------

export function getCompanies(): Promise<Company[]> {
  return makeRequest<void, { data: Company[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/company?perPage=-1`,
  }).then((response) => response.data.data.map(({ uuid, name }) => ({ uuid, name })));
}

export function getConfiguratorVehicles(): Promise<ConfiguratorVehicle[]> {
  return makeRequest<void, { data: ConfiguratorVehicle[] }>({
    type: 'get',
    url: 'https://portal2test.electricway.no/api/frontend/v1/configurator-vehicle?perPage=-1',
  }).then((response) => response.data.data);
}

export function getModifications(): Promise<Modification[]> {
  return makeRequest<void, { data: Modification[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/modification?perPage=-1`,
  }).then((response) => response.data.data);
}

export function getRoles(): Promise<Role[]> {
  return makeRequest<void, { data: Role[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/admin/v1/roles?perPage=-1`,
  }).then((response) => response.data.data);
}

export function getEventTypes(): Promise<EventType[]> {
  return makeRequest<void, { data: EventType[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/event-type?perPage=-1`,
  }).then((response) => response.data.data);
}
