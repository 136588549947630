import type { User } from 'src/types/auth/sanctum';
import type { Workshop, FormValidation } from 'src/types/cart';
import type { OrderItem } from 'src/types/after-sales/spare-parts';

import { m } from 'framer-motion';
import { useSanctum } from 'react-sanctum';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Radio from '@mui/material/Radio';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { convertToCurrency } from 'src/utils/format-number';
import { createOrder } from 'src/utils/requests/after-sales/spare-parts';

import { useCartStore, useCommentStore } from 'src/stores/cart';

import { toast } from 'src/components/snackbar';
import { varHover } from 'src/components/animate';
import { Scrollbar } from 'src/components/scrollbar';
import { LoadingScreen } from 'src/components/loading-screen';
import { TextField, Autocomplete } from 'src/components/fields';
import { ButtonWithConfirmation } from 'src/components/buttons';

import { CartItem } from './cart-item';

// ----------------------------------------------------------------------

export function CartDrawer() {
  const { t } = useTranslation();

  const router = useRouter();

  const pending = useCartStore((state) => state.itemsPending);
  const open = useCartStore((state) => state.open);
  const items = useCartStore((state) => state.items);
  const total = useCartStore((state) => state.total);
  const openCart = useCartStore((state) => state.openCart);
  const closeCart = useCartStore((state) => state.closeCart);
  const updateCart = useCartStore((state) => state.updateCart);
  const setItems = useCartStore((state) => state.setItems);
  const syncCart = useCartStore((state) => state.syncCart);

  const getComment = useCommentStore((state) => state.getComment);

  const { user } = useSanctum<User>();

  const [workshop, setWorkshop] = useState<Workshop | null>(
    user?.workshops.length === 1
      ? user.workshops[0]
      : null
  );
  const [comment, setComment] = useState('');
  const [deliveryType, setDeliveryType] = useState<'standard' | 'express'>('standard');
  const [loading, setLoading] = useState(false);
  const [formValidation, setFormValidation] = useState<FormValidation>({
    workshop: true,
    comment: true,
  });

  const validateForm = useCallback(() => {
    const validWorkshop = !!workshop;
    const validComment = comment !== '';

    setFormValidation({
      workshop: validWorkshop,
      comment: validComment,
    });

    return validWorkshop && validComment;
  }, [workshop, comment]);

  const submitAction = useCallback(async (openDialog: () => void) => {
    if (!validateForm()) {
      return;
    }

    if (await syncCart()) {
      openDialog();
    }
  }, [validateForm, syncCart]);

  const confirmAction = useCallback((closeDialog: () => void) => {
    closeDialog();
    closeCart();

    setLoading(true);

    const orderItems = items.map(({ availableQuantity, ...rest }): OrderItem => ({ ...rest, comment: getComment(rest.code) }));
    const deliveryTotal = deliveryType === 'standard' ? 0 : 500;

    createOrder({
      items: orderItems,
      workshopUuid: workshop!.uuid,
      comment,
      deliveryType,
      deliveryTotal,
      total: total + deliveryTotal,
    }).then((order) => {
      toast.success(t('orderCreated'));
      updateCart();
      router.push(paths.afterSales.spareParts.order, { state: order });
    }).catch(({ message }) => {
      toast.error(`${t('issueCreatingOrder')} - ${message}`)
    }).finally(() => {
      setLoading(false);
    });
  }, [
    items,
    getComment,
    workshop,
    comment,
    deliveryType,
    total,
    t,
    updateCart,
    router,
    closeCart,
  ]);

  useEffect(() => {
    setItems();
  }, [setItems]);

  return (
    <>
      {
        loading &&
        <LoadingScreen portal />
      }

      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={openCart}
        sx={{
          mr: items.length > 0 ? '9px' : 0,
        }}
      >
        <Badge
          badgeContent={items.length}
          sx={{
            width: 18,
            height: 18,

            '.MuiBadge-badge': {
              color: 'white',
              backgroundColor: 'var(--layout-toolbar-counter-color)'
            },
          }}
        >
          <SvgIcon
            viewBox="0 0 20 20"
            sx={{
              width: 20,
              height: 20,
              color: 'var(--layout-toolbar-icon-color)',
              fill: 'none',
            }}
          >
            <path
              d="M5.95833 17.6901C6.41857 17.6901 6.79167 17.317 6.79167 16.8568C6.79167 16.3965 6.41857 16.0234 5.95833 16.0234C5.4981 16.0234 5.125 16.3965 5.125 16.8568C5.125 17.317 5.4981 17.6901 5.95833 17.6901Z"
              stroke="var(--layout-toolbar-icon-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
              d="M15.1251 17.6901C15.5853 17.6901 15.9584 17.317 15.9584 16.8568C15.9584 16.3965 15.5853 16.0234 15.1251 16.0234C14.6648 16.0234 14.2917 16.3965 14.2917 16.8568C14.2917 17.317 14.6648 17.6901 15.1251 17.6901Z"
              stroke="var(--layout-toolbar-icon-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
              d="M1 1.06543H2.66667L4.88333 11.4154C4.96465 11.7945 5.17556 12.1333 5.47976 12.3736C5.78396 12.614 6.16242 12.7407 6.55 12.7321H14.7C15.0793 12.7315 15.4471 12.6015 15.7425 12.3636C16.038 12.1257 16.2434 11.7942 16.325 11.4238L17.7 5.2321H3.55833"
              stroke="var(--layout-toolbar-icon-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </SvgIcon>
        </Badge>
      </IconButton>

      <Drawer
        open={open}
        onClose={closeCart}
        anchor="right"
        PaperProps={{sx: {width: 1, maxWidth: 420}}}
      >
        <Stack
          sx={{
            padding: 3,
            boxShadow: 'inset 0 -1px 0 0 rgba(var(--palette-grey-500Channel) / 0.2)'
          }}
        >
          <Typography variant="h6">
            {t('cart')}
          </Typography>
        </Stack>

        <Stack
          sx={{
            height: 1,
            maxHeight: 620,
          }}
        >
          {
            pending &&
            <LoadingScreen/>
          }

          {
            !pending &&
            <Scrollbar>
              <Box
                component="ul"
                sx={{
                  padding: 3,
                  gap: 3,
                }}
              >
                {items?.map((item) => (
                  <CartItem
                    key={item.code}
                    cartItem={item}
                  />
                ))}
              </Box>
            </Scrollbar>
          }
        </Stack>

        <Stack sx={{ mx: 3, borderBottom: '1px dashed rgba(var(--palette-grey-500Channel) / 0.2)'}} />

        <Stack sx={{ padding: 3, gap: 3 }}>
          <Autocomplete
            inputProps={{
              label: t('deliveryLocation'),
              error: !formValidation.workshop,
              errorText: t('pleaseSelectDeliveryLocation'),
            }}
            value={workshop}
            getOptionLabel={(option) => option?.name ?? ''}
            options={user?.workshops ?? []}
            onChange={(event: any, newValue: Workshop | null) => setWorkshop(newValue)}
          />

          <TextField
            multiline
            rows={2}
            label={t('comments')}
            sx={{ width: 1 }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            error={!formValidation.comment}
            errorText={t('pleaseEnterComment')}
          />
        </Stack>

        <Stack sx={{ mx: 3, borderBottom: '1px dashed rgba(var(--palette-grey-500Channel) / 0.2)'}} />

        <Stack sx={{ py: 4, px: 3 }}>
          <FormControl sx={{ gap: 3 }}>
            <RadioGroup
              value={deliveryType}
              onChange={(e) => setDeliveryType(e.target.value as 'standard' | 'express')}
            >
              <FormControlLabel
                value="standard"
                control={<Radio color="default" />}
                label={t(`standardDelivery`)}
                labelPlacement="end"
              />

              <FormControlLabel
                value="express"
                control={<Radio color="default" />}
                label={`${t('expressDelivery')} - ${t('extraChargesMayApply')}`}
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </Stack>

        <Stack sx={{ mx: 3, borderBottom: '1px dashed rgba(var(--palette-grey-500Channel) / 0.2)'}} />

        <Stack sx={{ padding: 3, gap: 3 }}>
          <Stack sx={{ flexDirection: 'row' }}>
            <Typography
              variant="subtitle1"
              sx={{ width: '50%' }}
            >
              {t('total')}
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{ width: '50%', textAlign: 'right', color: 'var(--palette-primary-main)' }}
            >
              {convertToCurrency(total)}
            </Typography>
          </Stack>

          <ButtonWithConfirmation
            buttonProps={{
              variant: 'contained',
              size: 'large',
              disabled: items.length < 1,
              onClick: submitAction,
            }}
            dialogProps={{
              title: t('confirmOrder?'),
              actions: {
                element: (closeDialog) => <>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => confirmAction(closeDialog)}
                    disabled={items.length < 1 || pending}
                    sx={{ width: 1 }}
                  >
                    {t('confirm')}
                  </Button>

                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={closeDialog}
                    sx={{ width: 1 }}
                  >
                    {t('cancel')}
                  </Button>
                </>,
                sx: { padding: 0, gap: 1.5 },
              },
              sx: { minWidth: 360, py: 3, px: 6.75, gap: 8.75, textAlign: 'center' },
            }}
          >
            {t('confirmOrder')}
          </ButtonWithConfirmation>
        </Stack>
      </Drawer>
    </>
  );
}
