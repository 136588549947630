const ROOTS = {
  AUTH: '/auth',
  VEHICLE_CARD: '/vehicle-card',
  SALES: '/sales',
  AFTER_SALES: '/after-sales',
  MARKETING: '/marketing',
  CLOTHING_EXTERNAL: 'https://google.com',
  MERCHANDISE_EXTERNAL: 'https://google.com',
  ACADEMY: '/academy',
  ACADEMY_EXTERNAL: 'https://academy.electricway.no/',
  EPC_VOYAH: 'https://ast.voyah.cn/#/partsTechnology/partsCatalogDetail',
  EPC_DONGFENG: 'https://epc.dfpv.com.cn/gbs',
  MANAGEMENT: '/management',
  ADMINISTRATION: '/administration',
};

// ----------------------------------------------------------------------

export const paths = {
  faqs: '/faqs',
  minimalStore: 'https://mui.com/store/items/minimal-dashboard/',
  auth: {
    login: '/login',
    signIn: `${ROOTS.AUTH}/redirect`,
    requestAccess: '/request-access',
  },
  vehicleCard: (uuid: string) => `${ROOTS.VEHICLE_CARD}/${uuid}`,
  sales: {
    root: ROOTS.SALES,
    dashboard: `${ROOTS.SALES}/dashboard`,
    news: {
      root: `${ROOTS.SALES}/news`,
      view: (id: string) => `${ROOTS.SALES}/news/view/${id}`,
    },
    inventory: `${ROOTS.SALES}/inventory`,
    orderRequests: {
      root: `${ROOTS.SALES}/order-requests`,
      view: (uuid: string) => `${ROOTS.SALES}/order-requests/view/${uuid}`,
      new: `${ROOTS.SALES}/order-requests/new`,
    },
    orders: {
      root: `${ROOTS.SALES}/orders`,
      view: (uuid: string) => `${ROOTS.SALES}/orders/view/${uuid}`,
    },
    salesReporting: {
      root: `${ROOTS.SALES}/sales-reporting`,
      view: `${ROOTS.SALES}/sales-reporting/view`,
    },
    priceList: {
      root: `${ROOTS.SALES}/price-list/path=`,
      view: (path: string) => `${ROOTS.SALES}/price-list/path=${path}`,
    },
    appUserBinding: `${ROOTS.SALES}/app-user-binding`,
    resources: {
      root: `${ROOTS.SALES}/resources/path=`,
      view: (path: string) => `${ROOTS.SALES}/resources/path=${path}`,
    },
  },
  afterSales: {
    root: ROOTS.AFTER_SALES,
    dashboard: `${ROOTS.AFTER_SALES}/dashboard`,
    news: {
      root: `${ROOTS.AFTER_SALES}/news`,
      view: (id: string) => `${ROOTS.AFTER_SALES}/news/view/${id}`,
    },
    claims: {
      root: `${ROOTS.AFTER_SALES}/claims`,
      view: (uuid: string) => `${ROOTS.AFTER_SALES}/claims/view/${uuid}`,
      new: (uuid: string) => `${ROOTS.AFTER_SALES}/claims/new/${uuid}`,
    },
    technicalCampaigns: {
      root: `${ROOTS.AFTER_SALES}/technical-campaigns`,
      view: (uuid: string) => `${ROOTS.AFTER_SALES}/technical-campaigns/view/${uuid}`,
      new: `${ROOTS.AFTER_SALES}/technical-campaigns/new`,
    },
    service: {
      root: `${ROOTS.AFTER_SALES}/service`,
      new: (uuid: string) => `${ROOTS.AFTER_SALES}/service/new/${uuid}`,
    },
    epc: {
      voyah: ROOTS.EPC_VOYAH,
      dongfeng: ROOTS.EPC_DONGFENG,
    },
    spareParts: {
      root: `${ROOTS.AFTER_SALES}/spare-parts`,
      order: `${ROOTS.AFTER_SALES}/spare-parts/order`
    },
    orders: {
      root: `${ROOTS.AFTER_SALES}/orders`,
      view: (uuid: string) => `${ROOTS.AFTER_SALES}/orders/view/${uuid}`,
    },
    resources: {
      root: `${ROOTS.AFTER_SALES}/resources/path=`,
      view: (path: string) => `${ROOTS.AFTER_SALES}/resources/path=${path}`,
    },
  },
  marketing: {
    root: ROOTS.MARKETING,
    dashboard: `${ROOTS.MARKETING}/dashboard`,
    news: {
      root: `${ROOTS.MARKETING}/news`,
      view: (id: string) => `${ROOTS.MARKETING}/news/view/${id}`,
    },
    merchandise: {
      root: `${ROOTS.MARKETING}/merchandise`,
      external: {
        clothing: ROOTS.CLOTHING_EXTERNAL,
        merchandise: ROOTS.MERCHANDISE_EXTERNAL,
      },
      apply: {
        clothing: `${ROOTS.MARKETING}/merchandise/apply/clothing`,
        merchandise: `${ROOTS.MARKETING}/merchandise/apply/merchandise`,
      },
    },
    events: {
      root: `${ROOTS.MARKETING}/events`,
      view: (uuid: string) => `${ROOTS.MARKETING}/events/view/${uuid}`,
      new: `${ROOTS.MARKETING}/events/new`,
    },
    resources: {
      root: `${ROOTS.MARKETING}/resources/path=`,
      view: (path: string) => `${ROOTS.MARKETING}/resources/path=${path}`,
    },
  },
  academy: {
    root: ROOTS.ACADEMY,
    news: {
      root: `${ROOTS.ACADEMY}/news`,
      view: (id: string) => `${ROOTS.ACADEMY}/news/view/${id}`,
    },
    resources: {
      root: `${ROOTS.ACADEMY}/resources/path=`,
      view: (path: string) => `${ROOTS.ACADEMY}/resources/path=${path}`,
    },
    external: ROOTS.ACADEMY_EXTERNAL,
  },
  management: {
    root: ROOTS.MANAGEMENT,
    accessRequests: {
      root: `${ROOTS.MANAGEMENT}/access-requests`,
      view: (uuid: string) => `${ROOTS.MANAGEMENT}/access-requests/view/${uuid}`,
    },
    users: {
      root: `${ROOTS.MANAGEMENT}/users`,
      view: (uuid: string) => `${ROOTS.MANAGEMENT}/users/view/${uuid}`,
    },
  },
  administration: {
    root: ROOTS.ADMINISTRATION,
    entities: {
      root: (entity?: string) => `${ROOTS.ADMINISTRATION}/entities${entity ? `?entity=${entity}` : ''}`,
      view: (entity: string, uuid: string) => `${ROOTS.ADMINISTRATION}/entities/view/${entity}/${uuid}`,
      new: (entity: string) => `${ROOTS.ADMINISTRATION}/entities/new/${entity}`,
    },
    storage: `${ROOTS.ADMINISTRATION}/storage`,
    smtp: `${ROOTS.ADMINISTRATION}/smtp`,
  },
};
