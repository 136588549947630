import { Suspense } from 'react';
import { useNavigation, Outlet as RouterOutlet } from 'react-router-dom';

import { MainLayout } from 'src/layouts/core';

import { SplashScreen, LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

type Props = {
  excludeWrapper?: boolean,
  splashScreen?: boolean,
};

export function Outlet({ excludeWrapper, splashScreen }: Props) {
  const { state } = useNavigation();

  const layout = (
    <Suspense fallback={splashScreen ? <SplashScreen /> : <LoadingScreen />}>
      <RouterOutlet />
    </Suspense>
  );

  if (excludeWrapper) {
    return (
      <>
        {
          state === 'loading' &&
          <LoadingScreen portal />
        }

        {layout}
      </>
    );
  }

  return (
    <>
      {
        state === 'loading' &&
        <LoadingScreen portal />
      }

      <MainLayout>
        {layout}
      </MainLayout>
    </>
  );
}
