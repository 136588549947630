import type { OptionTypes, OptionObject } from 'src/types/global';

import { defer } from 'react-router-dom';

import {
  getRoles,
  getCompanies,
  getEventTypes,
  getModifications,
  getConfiguratorVehicles,
} from 'src/utils/requests/global';

// ----------------------------------------------------------------------

export const hasParams = (url: string): boolean => {
  const queryString = url.split('?')[1];
  return queryString ? new URLSearchParams(queryString).toString().length > 0 : false;
};

// ----------------------------------------------------------------------

export function removeLastSlash(pathname: string): string {
  /**
   * Remove last slash
   * [1]
   * @input  = '/dashboard/calendar/'
   * @output = '/dashboard/calendar'
   * [2]
   * @input  = '/dashboard/calendar'
   * @output = '/dashboard/calendar'
   */
  if (pathname !== '/' && pathname.endsWith('/')) {
    return pathname.slice(0, -1);
  }

  return pathname;
}

// ----------------------------------------------------------------------

export function removeParams(url: string): string {
  try {
    const urlObj = new URL(url, window.location.origin);

    return removeLastSlash(urlObj.pathname);
  } catch (error) {
    return url;
  }
}

// ----------------------------------------------------------------------

export function isExternalLink(url: string): boolean {
  return url.startsWith('http');
}

// ----------------------------------------------------------------------

export async function optionsLoader(hasAccess: boolean, optionTypes: OptionTypes) {
  if (!hasAccess) {
    const emptyPromise = new Promise<any>((resolve) => resolve([]));

    return defer(
      optionTypes.reduce((map: OptionObject, optionType) => {
        map[`${optionType}Options`] = emptyPromise;

        return map;
      }, {}),
    );
  }

  return defer(
    optionTypes.reduce((map: OptionObject, optionType) => {
      switch (optionType) {
        case 'company':
          map[`${optionType}Options`] = getCompanies();
          break;
        case 'configuratorVehicle':
          map[`${optionType}Options`] = getConfiguratorVehicles();
          break;
        case 'modification':
          map[`${optionType}Options`] = getModifications();
          break;
        case 'role':
          map[`${optionType}Options`] = getRoles();
          break;
        case 'eventType':
          map[`${optionType}Options`] = getEventTypes();
          break;
        default:
          break;
      }

      return map;
    }, {}),
  );
}
