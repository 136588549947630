import type { User } from 'src/types/auth/sanctum';
import type { IconButtonProps } from '@mui/material/IconButton';

import { useSanctum } from 'react-sanctum';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { logout } from 'src/utils/requests/auth';

import { toast } from 'src/components/snackbar';
import { SettingsPopover } from 'src/components/settings';
import { usePopover } from 'src/components/custom-popover';
import { LoadingScreen } from 'src/components/loading-screen';

import { AccountButton } from './account-button';
import { LanguagePopover } from './language-popover';

// ----------------------------------------------------------------------

export type AccountMenuProps = IconButtonProps & {
  data?: {
    value: string,
    label: string,
    countryCode: string,
  }[],
};

export function AccountMenu({ data = [], sx, ...other }: AccountMenuProps) {
  const { t } = useTranslation();

  const { user } = useSanctum<User>();

  const router = useRouter();

  const popover = usePopover();

  const [loading, setLoading] = useState(false);

  const logoutAction = useCallback(() => {
    setLoading(true);

    logout().then(() => {
      toast.success(t('successfullyLoggedOut'));
      router.replace(paths.auth.login);
    }).catch(({ message }) => {
      toast.error(`${t('issueLoggingOut')} - ${message}`);
    }).finally(() => {
      setLoading(false);
    })
  }, [t, router]);

  return (
    <>
      {
        loading &&
        <LoadingScreen portal />
      }

      <AccountButton
        open={popover.open}
        onClick={popover.open ? popover.onClose : popover.onOpen}
        sx={sx}
        {...other}
      />

      <Popover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              minWidth: 224,
              width: 'auto',
              mt: '11px',
              p: 0,
              left: 'unset !important',
              right: 24,
              border: '1px solid var(--layout-nav-border-color)',
              borderRadius: 1,
              backgroundColor: 'background.paper',
              zIndex: 1200,
            },
          },
        }}
      >
        <Box>
          <Stack sx={{ p: 2, gap: 1 }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, lineHeight: '22px' }}
            >
              {user?.name}
            </Typography>

            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', fontWeight: 500, lineHeight: '22px' }}
            >
              {user?.email}
            </Typography>
          </Stack>

          <Stack sx={{ borderBottom: '1px solid var(--layout-nav-border-color)'}} />

          <Stack sx={{ py: 1 }}>
            <LanguagePopover data-slot="localization" data={data} />

            <SettingsPopover data-slot="settings" />
          </Stack>

          <Stack sx={{ borderBottom: '1px solid var(--layout-nav-border-color)'}} />

          <Stack sx={{ p: 2 }}>
            <Button
              disableRipple
              onClick={logoutAction}
              sx={{
                p: 0,
                gap: 0.75,
                justifyContent: 'start',

                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <SvgIcon
                viewBox="0 0 20 20"
                sx={{
                  width: 20,
                  height: 20,
                  fill: 'none',
                }}
              >
                <path
                  d="M7.5 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H7.5"
                  stroke="#DC2625" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.332 14.1668L17.4987 10.0002L13.332 5.8335" stroke="#DC2625" strokeWidth="2"
                      strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M17.5 10H7.5" stroke="#DC2625" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
              </SvgIcon>

              <Typography
                variant="body2"
                sx={{ color: '#DC2625', lineHeight: '22px' }}
              >
                {t('logout')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </Popover>
    </>
  );
}
