import { createBrowserRouter } from 'react-router-dom';

import { Navigate, LayoutWrapper } from 'src/routes/components';

import { useHasAccess } from 'src/auth/hooks';
import { AuthGuardWrapper } from 'src/auth/guard';

import { AuthRoutes } from './auth';
import { MainRoutes } from './main';
import { SalesRoutes } from './sales';
import { AcademyRoutes } from './academy';
import { MarketingRoutes } from './marketing';
import { ManagementRoutes } from './management';
import { AfterSalesRoutes } from './after-sales';
import { VehicleCardRoutes } from './vehicle-card';
import { AdministrationRoutes } from './administration';

// ----------------------------------------------------------------------

export function Router() {
  const { redirectPath } = useHasAccess();

  return createBrowserRouter([
    {
      element: <LayoutWrapper />,
      children: [
        {
          path: '/',
          element: (
            <AuthGuardWrapper>
              <Navigate to={redirectPath} replace />
            </AuthGuardWrapper>
          ),
        },

        ...AuthRoutes(),

        ...MainRoutes(),

        ...VehicleCardRoutes(),

        ...SalesRoutes(),

        ...AfterSalesRoutes(),

        ...MarketingRoutes(),

        ...AcademyRoutes(),

        ...ManagementRoutes(),

        ...AdministrationRoutes(),

        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}
