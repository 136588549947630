import type { CartItem } from 'src/types/cart';

import { makeRequest } from './utils';

// ----------------------------------------------------------------------

export function getCartItems(): Promise<{ items: CartItem[] }> {
  return makeRequest<void, { data: { items: CartItem[] } }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/cart`,
  }).then((response) => response.data.data);
}

export function addOrUpdateCartItem(code: string, quantity: number): Promise<{ items: CartItem[] }> {
  return makeRequest<{ code: string, quantity: number }, { data: { items: CartItem[] } }>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/cart/items`,
    data: { code, quantity },
  }).then((response) => response.data.data);
}

export function syncCartItems(items: CartItem[]): Promise<{ items: CartItem[] }> {
  return makeRequest<{ items: CartItem[] }, { data: { items: CartItem[] } }>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/cart/sync`,
    data: { items },
  }).then((response) => response.data.data);
}
