import ReactDOM from 'react-dom/client';
import { Suspense, StrictMode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './i18n';
import App from './app';
import { ConfigInjector } from './components/config-injector';
import { GlobalErrorView } from './sections/error/global-error-view';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 0,
    },
  },
});

// Reload the page on failed dynamic imports
window.addEventListener('vite:preloadError', () => {
  setTimeout(() => window.location.reload(), 500);
});

root.render(
  <StrictMode>
    <ErrorBoundary FallbackComponent={GlobalErrorView}>
      <ConfigInjector>
        <QueryClientProvider client={queryClient}>
          <Suspense>
            <App />
          </Suspense>
        </QueryClientProvider>
      </ConfigInjector>
    </ErrorBoundary>
  </StrictMode>
);
