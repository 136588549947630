import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import SvgIcon from '@mui/material/SvgIcon';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useColorScheme } from '@mui/material/styles';

import COLORS from 'src/theme/core/colors.json';
import PRIMARY_COLOR from 'src/theme/with-settings/primary-color.json';

import { ArrowDownIcon } from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';

import { useSettingsContext } from '../context';

import type { SettingsDrawerProps } from '../types';

// ----------------------------------------------------------------------

export function SettingsPopover(
  {
    sx,
    hideFont,
    hideCompact,
    hidePresets,
    hideNavColor,
    hideContrast,
    hideNavLayout,
    hideDirection,
    hideColorScheme,
  }: SettingsDrawerProps
) {
  const { t } = useTranslation();

  const popover = usePopover();

  const settings = useSettingsContext();

  const { mode, setMode } = useColorScheme();

  return (
    <>
      <Button
        sx={{
          py: 1,
          px: 2,
          borderRadius: 0,
          color: popover.open ? 'text.primary' : 'text.secondary',
          backgroundColor: popover.open ? 'var(--palette-action-hover)' : 'transparent',

          '&:hover': {
            color: 'text.primary',
          },

          'path': {
            stroke: popover.open ? 'var(--palette-text-primary)' : 'var(--palette-text-secondary)',
          },

          '&:hover path': {
            stroke: 'var(--palette-text-primary)',
          },
        }}
        onClick={popover.onOpen}
      >
        <Stack sx={{ width: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
            <SvgIcon
              viewBox="0 0 20 20"
              sx={{
                width: 20,
                height: 20,
                fill: 'none',
              }}
            >
              <path d="M16.668 5.83325H9.16797" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round" />
              <path d="M11.668 14.1667H4.16797" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round" />
              <path
                d="M14.168 16.6667C15.5487 16.6667 16.668 15.5475 16.668 14.1667C16.668 12.786 15.5487 11.6667 14.168 11.6667C12.7873 11.6667 11.668 12.786 11.668 14.1667C11.668 15.5475 12.7873 16.6667 14.168 16.6667Z"
                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M5.83203 8.33325C7.21274 8.33325 8.33203 7.21396 8.33203 5.83325C8.33203 4.45254 7.21274 3.33325 5.83203 3.33325C4.45132 3.33325 3.33203 4.45254 3.33203 5.83325C3.33203 7.21396 4.45132 8.33325 5.83203 8.33325Z"
                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </SvgIcon>

            <Typography
              variant="body2"
              sx={{ lineHeight: '22px' }}
            >
              {t('themeSettings')}
            </Typography>
          </Stack>

          <Stack sx={{ justifyContent: 'center' }}>
            <ArrowDownIcon sx={{ width: 16, height: 16, transform: 'rotate(-90deg)' }} />
          </Stack>
        </Stack>
      </Button>

      <Popover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              minWidth: 320,
              width: 'auto',
              ml: '5px',
              p: 0,
              color: mode === 'dark' ? 'text.primary' : 'text.secondary',
              border: '1px solid var(--layout-nav-border-color)',
              borderRadius: 1,
              backgroundColor: 'background.paper',
              zIndex: 1200,

              'path': {
                stroke: mode === 'dark' ? 'var(--palette-text-primary)' : 'var(--palette-text-secondary)',
              },
            },
          },
        }}
      >
        <Stack sx={{ p: 2, gap: 2 }}>
          <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
              <SvgIcon
                viewBox="0 0 20 20"
                sx={{
                  width: 20,
                  height: 20,
                  fill: 'none',
                }}
              >
                <path
                  d="M10 2.5C9.00544 3.49456 8.4467 4.84348 8.4467 6.25C8.4467 7.65652 9.00544 9.00544 10 10C10.9946 10.9946 12.3435 11.5533 13.75 11.5533C15.1565 11.5533 16.5054 10.9946 17.5 10C17.5 11.4834 17.0601 12.9334 16.236 14.1668C15.4119 15.4001 14.2406 16.3614 12.8701 16.9291C11.4997 17.4968 9.99168 17.6453 8.53683 17.3559C7.08197 17.0665 5.7456 16.3522 4.6967 15.3033C3.64781 14.2544 2.9335 12.918 2.64411 11.4632C2.35472 10.0083 2.50325 8.50032 3.07091 7.12987C3.63856 5.75943 4.59986 4.58809 5.83323 3.76398C7.0666 2.93987 8.51664 2.5 10 2.5Z"
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </SvgIcon>

              <Typography
                variant="body2"
                sx={{ lineHeight: '22px' }}
              >
                {t('darkMode')}
              </Typography>
            </Stack>

            <Switch
              size="small"
              color="primary"
              checked={mode === 'dark'}
              onClick={() => {
                settings.onUpdateField('colorScheme', mode === 'light' ? 'dark' : 'light');
                setMode(mode === 'light' ? 'dark' : 'light');
              }}
              sx={{
                '& .MuiSwitch-track': {
                  backgroundColor: 'var(--palette-text-secondary)',
                },

                '&.Mui-checked .MuiSwitch-track': {
                  backgroundColor: 'var(--palette-primary-main)',
                },
              }}
            />
          </Stack>

          <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
              <SvgIcon
                viewBox="0 0 20 20"
                sx={{
                  width: 20,
                  height: 20,
                  fill: 'none',
                }}
              >
                <g clipPath="url(#clip0_2649_39988)">
                  <path
                    d="M10.0013 18.3334C14.6037 18.3334 18.3346 14.6025 18.3346 10.0001C18.3346 5.39771 14.6037 1.66675 10.0013 1.66675C5.39893 1.66675 1.66797 5.39771 1.66797 10.0001C1.66797 14.6025 5.39893 18.3334 10.0013 18.3334Z"
                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M10 15C11.3261 15 12.5979 14.4732 13.5355 13.5355C14.4732 12.5979 15 11.3261 15 10C15 8.67392 14.4732 7.40215 13.5355 6.46447C12.5979 5.52678 11.3261 5 10 5V15Z"
                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_2649_39988">
                    <rect width="20" height="20" />
                  </clipPath>
                </defs>
              </SvgIcon>

              <Typography
                variant="body2"
                sx={{lineHeight: '22px'}}
              >
                {t('highContrast')}
              </Typography>
            </Stack>

            <Switch
              size="small"
              color="primary"
              checked={settings.contrast === 'hight'}
              onClick={() =>
                settings.onUpdateField('contrast', settings.contrast === 'default' ? 'hight' : 'default')
              }
              sx={{
                '& .MuiSwitch-track': {
                  backgroundColor: 'var(--palette-text-secondary)',
                },

                '&.Mui-checked .MuiSwitch-track': {
                  backgroundColor: 'var(--palette-primary-main)',
                },
              }}
            />
          </Stack>

          <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
              <SvgIcon
                viewBox="0 0 20 20"
                sx={{
                  width: 20,
                  height: 20,
                  fill: 'none',
                }}
              >
                <path
                  d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z"
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.5 2.5V17.5" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
              </SvgIcon>

              <Typography
                variant="body2"
                sx={{lineHeight: '22px'}}
              >
                {t('rightToLeft')}
              </Typography>
            </Stack>

            <Switch
              size="small"
              color="primary"
              checked={settings.direction === 'rtl'}
              onClick={() =>
                settings.onUpdateField('direction', settings.direction === 'ltr' ? 'rtl' : 'ltr')
              }
              sx={{
                '& .MuiSwitch-track': {
                  backgroundColor: 'var(--palette-text-secondary)',
                },

                '&.Mui-checked .MuiSwitch-track': {
                  backgroundColor: 'var(--palette-primary-main)',
                },
              }}
            />
          </Stack>
        </Stack>

        <Stack sx={{ borderBottom: '1px solid var(--layout-nav-border-color)'}} />

        <Stack sx={{ p: 2, gap: '13px', textTransform: 'uppercase' }}>
          <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography
              variant="body2"
              sx={{ lineHeight: '32px' }}
            >
              {t('layout')}
            </Typography>

            <Stack sx={{ flexDirection: 'row', gap: 1.25, alignItems: 'center' }}>
              <Button
                sx={{
                  minWidth: 'unset',
                  p: 0.75,
                  backgroundColor: settings.navLayout === 'vertical'
                    ? mode === 'dark'
                      ? 'var(--palette-text-primary)'
                      : 'var(--palette-action-hover)'
                    : 'transparent',
                  pointerEvents: settings.navLayout === 'vertical' ? 'none' : 'all',

                  '&:hover': {
                    backgroundColor: mode === 'dark' ? 'var(--palette-text-primary)' : 'var(--palette-action-hover)',
                  },

                  'path': {
                    stroke: settings.navLayout === 'vertical'
                      ? mode === 'dark'
                        ? '#1C252E'
                        : 'var(--palette-text-primary)'
                      : mode === 'dark'
                        ? 'var(--palette-text-primary)'
                        : 'var(--palette-text-secondary)',
                  },

                  '&:hover path': {
                    stroke: mode === 'dark'
                      ? '#1C252E'
                      : 'var(--palette-text-primary)',
                  },
                }}
                onClick={() => settings.onUpdateField('navLayout', 'vertical')}
              >
                <SvgIcon
                  viewBox="0 0 20 20"
                  sx={{
                    width: 20,
                    height: 20,
                    fill: 'none',
                  }}
                >
                  <g clipPath="url(#clip0_2649_40016)">
                    <path
                      d="M5.0013 1.66675H2.5013C2.04106 1.66675 1.66797 2.03984 1.66797 2.50008V17.5001C1.66797 17.9603 2.04106 18.3334 2.5013 18.3334H5.0013C5.46154 18.3334 5.83464 17.9603 5.83464 17.5001V2.50008C5.83464 2.03984 5.46154 1.66675 5.0013 1.66675Z"
                      strokeWidth="1.5"/>
                    <path
                      d="M17.5 1.66675H8.33333C7.8731 1.66675 7.5 2.03984 7.5 2.50008V17.5001C7.5 17.9603 7.8731 18.3334 8.33333 18.3334H17.5C17.9602 18.3334 18.3333 17.9603 18.3333 17.5001V2.50008C18.3333 2.03984 17.9602 1.66675 17.5 1.66675Z"
                      strokeWidth="1.5"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_2649_40016">
                      <rect width="20" height="20" />
                    </clipPath>
                  </defs>
                </SvgIcon>
              </Button>

              <Button
                sx={{
                  minWidth: 'unset',
                  p: 0.75,
                  backgroundColor: settings.navLayout === 'horizontal'
                    ? mode === 'dark'
                      ? 'var(--palette-text-primary)'
                      : 'var(--palette-action-hover)'
                    : 'transparent',
                  pointerEvents: settings.navLayout === 'horizontal' ? 'none' : 'all',

                  '&:hover': {
                    backgroundColor: mode === 'dark' ? 'var(--palette-text-primary)' : 'var(--palette-action-hover)',
                  },

                  'path': {
                    stroke: settings.navLayout === 'horizontal'
                      ? mode === 'dark'
                        ? '#1C252E'
                        : 'var(--palette-text-primary)'
                      : mode === 'dark'
                        ? 'var(--palette-text-primary)'
                        : 'var(--palette-text-secondary)',
                  },

                  '&:hover path': {
                    stroke: mode === 'dark'
                      ? '#1C252E'
                      : 'var(--palette-text-primary)',
                  },
                }}
                onClick={() => settings.onUpdateField('navLayout', 'horizontal')}
              >
                <SvgIcon
                  viewBox="0 0 20 20"
                  sx={{
                    width: 20,
                    height: 20,
                    fill: 'none',
                  }}
                >
                  <g clipPath="url(#clip0_2649_39966)">
                    <path
                      d="M17.5013 1.66675H2.5013C2.04106 1.66675 1.66797 2.03984 1.66797 2.50008V4.16675C1.66797 4.62699 2.04106 5.00008 2.5013 5.00008H17.5013C17.9615 5.00008 18.3346 4.62699 18.3346 4.16675V2.50008C18.3346 2.03984 17.9615 1.66675 17.5013 1.66675Z"
                      strokeWidth="1.5"/>
                    <path
                      d="M17.5013 6.66675H2.5013C2.04106 6.66675 1.66797 7.03984 1.66797 7.50008V17.5001C1.66797 17.9603 2.04106 18.3334 2.5013 18.3334H17.5013C17.9615 18.3334 18.3346 17.9603 18.3346 17.5001V7.50008C18.3346 7.03984 17.9615 6.66675 17.5013 6.66675Z"
                      strokeWidth="1.5"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_2649_39966">
                      <rect width="20" height="20" />
                    </clipPath>
                  </defs>
                </SvgIcon>
              </Button>
            </Stack>
          </Stack>

          <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography
              variant="body2"
              sx={{ lineHeight: '32px' }}
            >
              {t('integrate')}
            </Typography>

            <Stack sx={{ flexDirection: 'row', gap: 1.25, alignItems: 'center' }}>
              <Button
                sx={{
                  minWidth: 'unset',
                  p: 0.75,
                  backgroundColor: settings.navColor === 'integrate'
                    ? mode === 'dark'
                      ? 'var(--palette-text-primary)'
                      : 'var(--palette-action-hover)'
                    : 'transparent',
                  pointerEvents: settings.navColor === 'integrate' ? 'none' : 'all',

                  '&:hover': {
                    backgroundColor: mode === 'dark' ? 'var(--palette-text-primary)' : 'var(--palette-action-hover)',
                  },

                  'path': {
                    stroke: settings.navColor === 'integrate'
                      ? mode === 'dark'
                        ? '#1C252E'
                        : 'var(--palette-text-primary)'
                      : mode === 'dark'
                        ? 'var(--palette-text-primary)'
                        : 'var(--palette-text-secondary)',
                  },

                  '&:hover path': {
                    stroke: mode === 'dark'
                      ? '#1C252E'
                      : 'var(--palette-text-primary)',
                  },
                }}
                onClick={() => settings.onUpdateField('navColor', 'integrate')}
              >
                <SvgIcon
                  viewBox="0 0 20 20"
                  sx={{
                    width: 20,
                    height: 20,
                    fill: 'none',
                  }}
                >
                  <g clipPath="url(#clip0_2649_40016)">
                    <path
                      d="M5.0013 1.66675H2.5013C2.04106 1.66675 1.66797 2.03984 1.66797 2.50008V17.5001C1.66797 17.9603 2.04106 18.3334 2.5013 18.3334H5.0013C5.46154 18.3334 5.83464 17.9603 5.83464 17.5001V2.50008C5.83464 2.03984 5.46154 1.66675 5.0013 1.66675Z"
                      strokeWidth="1.5"/>
                    <path
                      d="M17.5 1.66675H8.33333C7.8731 1.66675 7.5 2.03984 7.5 2.50008V17.5001C7.5 17.9603 7.8731 18.3334 8.33333 18.3334H17.5C17.9602 18.3334 18.3333 17.9603 18.3333 17.5001V2.50008C18.3333 2.03984 17.9602 1.66675 17.5 1.66675Z"
                      strokeWidth="1.5"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_2649_40016">
                      <rect width="20" height="20" />
                    </clipPath>
                  </defs>
                </SvgIcon>
              </Button>

              <Button
                sx={{
                  minWidth: 'unset',
                  p: 0.75,
                  backgroundColor: settings.navColor === 'apparent'
                    ? mode === 'dark'
                      ? 'var(--palette-text-primary)'
                      : 'var(--palette-action-hover)'
                    : 'transparent',
                  pointerEvents: settings.navColor === 'apparent' ? 'none' : 'all',

                  '&:hover': {
                    backgroundColor: mode === 'dark' ? 'var(--palette-text-primary)' : 'var(--palette-action-hover)',
                  },

                  'path': {
                    stroke: settings.navColor === 'apparent'
                      ? mode === 'dark'
                        ? '#1C252E'
                        : 'var(--palette-text-primary)'
                      : mode === 'dark'
                        ? 'var(--palette-text-primary)'
                        : 'var(--palette-text-secondary)',
                  },

                  'path:first-of-type': {
                    fill: settings.navColor === 'apparent'
                      ? mode === 'dark'
                        ? '#1C252E'
                        : 'var(--palette-text-primary)'
                      : mode === 'dark'
                        ? 'var(--palette-text-primary)'
                        : 'var(--palette-text-secondary)',
                  },

                  '&:hover path': {
                    stroke: mode === 'dark'
                      ? '#1C252E'
                      : 'var(--palette-text-primary)',
                  },

                  '&:hover path:first-of-type': {
                    fill: mode === 'dark' ? '#1C252E' : 'var(--palette-text-primary)',
                  },
                }}
                onClick={() => settings.onUpdateField('navColor', 'apparent')}
              >
                <SvgIcon
                  viewBox="0 0 20 20"
                  sx={{
                    width: 20,
                    height: 20,
                    fill: 'none',
                  }}
                >
                  <g clipPath="url(#clip0_2649_40019)">
                    <path
                      d="M5.0013 1.66675H2.5013C2.04106 1.66675 1.66797 2.03984 1.66797 2.50008V17.5001C1.66797 17.9603 2.04106 18.3334 2.5013 18.3334H5.0013C5.46154 18.3334 5.83464 17.9603 5.83464 17.5001V2.50008C5.83464 2.03984 5.46154 1.66675 5.0013 1.66675Z"
                      strokeWidth="1.5" />
                    <path
                      d="M17.5 1.66675H8.33333C7.8731 1.66675 7.5 2.03984 7.5 2.50008V17.5001C7.5 17.9603 7.8731 18.3334 8.33333 18.3334H17.5C17.9602 18.3334 18.3333 17.9603 18.3333 17.5001V2.50008C18.3333 2.03984 17.9602 1.66675 17.5 1.66675Z"
                      strokeWidth="1.5"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_2649_40019">
                      <rect width="20" height="20" />
                    </clipPath>
                  </defs>
                </SvgIcon>
              </Button>
            </Stack>
          </Stack>
        </Stack>

        <Stack sx={{borderBottom: '1px solid var(--layout-nav-border-color)'}}/>

        <Stack sx={{ p: 2, textTransform: 'uppercase' }}>
          <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography
              variant="body2"
              sx={{ lineHeight: '32px' }}
            >
              {t('accentColor')}
            </Typography>

            <Stack sx={{ flexDirection: 'row', gap: 0.25, alignItems: 'center' }}>
              <Button
                sx={{
                  minWidth: 'unset',
                  p: '5px',
                  border: '2px solid',
                  borderColor: settings.primaryColor === 'default' ? mode === 'dark' ? 'var(--palette-text-primary)' : '#919EAB' : 'transparent',
                  pointerEvents: settings.primaryColor === 'default' ? 'none' : 'all',

                  '&:hover': {
                    borderColor: mode === 'dark' ? 'var(--palette-text-primary)' : '#919EAB',
                  },
                }}
                onClick={() => settings.onUpdateField('primaryColor', 'default')}
              >
                <Box
                  sx={{
                    width: 18,
                    height: 18,
                    backgroundColor: COLORS.primary.main,
                    borderRadius: '50%',
                  }}
                />
              </Button>

              <Button
                sx={{
                  minWidth: 'unset',
                  p: '5px',
                  border: '2px solid',
                  borderColor: settings.primaryColor === 'blue' ? mode === 'dark' ? 'var(--palette-text-primary)' : '#919EAB' : 'transparent',
                  pointerEvents: settings.primaryColor === 'blue' ? 'none' : 'all',

                  '&:hover': {
                    borderColor: mode === 'dark' ? 'var(--palette-text-primary)' : '#919EAB',
                  },
                }}
                onClick={() => settings.onUpdateField('primaryColor', 'blue')}
              >
                <Box
                  sx={{
                    width: 18,
                    height: 18,
                    backgroundColor: PRIMARY_COLOR.blue.main,
                    borderRadius: '50%',
                  }}
                />
              </Button>

              <Button
                sx={{
                  minWidth: 'unset',
                  p: '5px',
                  border: '2px solid',
                  borderColor: settings.primaryColor === 'purple' ? mode === 'dark' ? 'var(--palette-text-primary)' : '#919EAB' : 'transparent',
                  pointerEvents: settings.primaryColor === 'purple' ? 'none' : 'all',

                  '&:hover': {
                    borderColor: mode === 'dark' ? 'var(--palette-text-primary)' : '#919EAB',
                  },
                }}
                onClick={() => settings.onUpdateField('primaryColor', 'purple')}
              >
                <Box
                  sx={{
                    width: 18,
                    height: 18,
                    backgroundColor: PRIMARY_COLOR.purple.main,
                    borderRadius: '50%',
                  }}
                />
              </Button>

              <Button
                sx={{
                  minWidth: 'unset',
                  p: '5px',
                  border: '2px solid',
                  borderColor: settings.primaryColor === 'red' ? mode === 'dark' ? 'var(--palette-text-primary)' : '#919EAB' : 'transparent',
                  pointerEvents: settings.primaryColor === 'red' ? 'none' : 'all',

                  '&:hover': {
                    borderColor: mode === 'dark' ? 'var(--palette-text-primary)' : '#919EAB',
                  },
                }}
                onClick={() => settings.onUpdateField('primaryColor', 'red')}
              >
                <Box
                  sx={{
                    width: 18,
                    height: 18,
                    backgroundColor: PRIMARY_COLOR.red.main,
                    borderRadius: '50%',
                  }}
                />
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
}
