import type { PaperProps } from '@mui/material/Paper';
import type { Theme, SxProps } from '@mui/material/styles';
import type { SelectProps as BaseSelectProps } from '@mui/material/Select';

import { forwardRef } from 'react';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';

import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import BaseSelect from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';

type SelectProps<Value = unknown> = Omit<BaseSelectProps<Value>, 'id'> & {
  id: string,
  label?: string,
  options: (string | number)[],
  errorText?: string,
  formControlProps?: {
    sx?: SxProps<Theme>,
  },
};

const PaperComponent = forwardRef<HTMLDivElement, PaperProps>((props, ref) => (
  <Paper {...props} ref={ref}>
    <SimpleBar children={props.children} style={{ maxHeight: 200 }} scrollbarMaxSize={64} />
  </Paper>
));

export function Select<Value>({ id, label, options, errorText, formControlProps, error, disabled, ...other }: SelectProps<Value>) {
  const { t } = useTranslation();

  return <FormControl
    disabled={disabled}
    sx={{
      ...(
        error &&
        {
          'label': {
            color: 'error.main',

            '&.MuiInputLabel-shrink.Mui-focused': {
              color: 'error.main',
            }
          },
        }
      ),

      ...formControlProps?.sx
    }}
  >
    {label && <InputLabel htmlFor={id}>{label}</InputLabel>}

    <BaseSelect
      input={<OutlinedInput label={label} error={error} />}
      inputProps={{ id }}
      sx={{
        // '.MuiSelect-outlined.Mui-disabled': {
        //   backgroundColor: 'var(--layout-disabled-input-background)',
        // },

        '.MuiSelect-select.MuiOutlinedInput-input': {
          height: 21,
          minHeight: 'unset',
        },
      }}
      MenuProps={{
        PaperProps: {
          component: PaperComponent,
        },
        MenuListProps: {
          sx: { overflow: 'hidden', maxHeight: 'unset' },
        },
      }}
      error={error}
      {...other}
    >
      {
        options.map((option) => (
          <MenuItem key={option} value={option}>
            {t(typeof option === 'number' ? option.toString() : option)}
          </MenuItem>
        ))
      }
    </BaseSelect>

    {
      error &&
      <FormHelperText>
        <Typography variant="subtitle2" sx={{ color: 'error.main' }}>{errorText}</Typography>
      </FormHelperText>
    }
  </FormControl>
}
