import type { Labor, SparePart, SearchResultData } from 'src/types/search';

import { makeRequest } from './utils';

// ----------------------------------------------------------------------

export function getGlobalSearchResults(query: string): Promise<SearchResultData> {
  return makeRequest<void, { data: SearchResultData }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/search/global?search=${query}`,
  }).then((response) => response.data.data);
}

export function getLaborSearchResults(uuid: string, query: string): Promise<Labor[]> {
  return makeRequest<void, { data: Labor[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/modification/${uuid}/labor?search=${query}&perPage=500`,
  }).then(
    (response) => response.data.data
  ).catch(() => []);
}

export function getSparePartSearchResults(uuid: string, query: string): Promise<SparePart[]> {
  return makeRequest<void, { data: SparePart[] }>({
    type: 'get',
    url: `https://portal2test.electricway.no/api/frontend/v1/modification/${uuid}/spare-part?search=${query}&perPage=500`,
  }).then(
    (response) => response.data.data
  ).catch(() => []);
}
