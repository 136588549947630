import type { User } from 'src/types/auth/sanctum';

import { useSanctum } from 'react-sanctum';
import { useMemo, useCallback } from 'react';

export function useHasAccess(requiredAccess?: string | string[], operator: 'or' | 'and' | 'any' | 'not' = 'and') {
  const { user } = useSanctum<User>();

  const hasAccess = useMemo(() => {
    if (!user) {
      return false;
    }

    if (!requiredAccess) {
      return true;
    }

    const roles = user.roles.length > 0 ? user.roles : ['super_admin'];

    if (!Array.isArray(requiredAccess)) {
      if (operator === 'not') {
        return !roles.includes(requiredAccess);
        // return user.roles.every((role) => role.access.every((access) => !access.includes(requiredAccess)));
      }

      if (operator === 'any') {
        return roles.includes(requiredAccess);
        // return user.roles.some((role) => role.access.some((access) => access.includes(requiredAccess)));
      }

      return roles.includes(requiredAccess);
      // const [region, section] = requiredAccess.split('.');
      //
      // return user.roles.some((role) => {
      //   if (role.access.includes(`${region}.${section}.*`)) {
      //     return true;
      //   }
      //
      //   return role.access.includes(requiredAccess);
      // });
    }

    const requiredSet = new Set<string>(requiredAccess);
    const availableSet = new Set<string>(roles);
    // const availableSet = new Set<string>([]);

    // user.roles.forEach((role) => {
    //   requiredAccess.forEach((required) => {
    //     const [region, section] = required.split('.');
    //
    //     if (role.access.includes(`${region}.${section}.*`)) {
    //       availableSet.add(required);
    //     }
    //   });
    //
    //   role.access.forEach((access) => {
    //     if (requiredSet.has(access)) {
    //       availableSet.add(access);
    //     }
    //   });
    // });

    if (operator === 'not') {
      if (typeof availableSet.intersection !== 'function') {
        return Array.from(availableSet).every((availableAccess) => !requiredSet.has(availableAccess));
      }

      return availableSet.intersection(requiredSet).size === 0;
      // return availableSet.size === 0;
    }

    if (operator === 'or') {
      if (typeof availableSet.intersection !== 'function') {
        return Array.from(availableSet).some((availableAccess) => requiredSet.has(availableAccess));
      }

      return availableSet.intersection(requiredSet).size > 0;
    }

    if (typeof availableSet.isSupersetOf !== 'function') {
      return Array.from(requiredSet).every((required) => availableSet.has(required));
    }

    return availableSet.isSupersetOf(requiredSet);
  }, [user, requiredAccess, operator]);

  const getData = useCallback((data: any, fallback: any) => {
    if (!hasAccess) {
      return fallback;
    }

    return data;
  }, [hasAccess]);

  const getRedirectPath = useCallback(() => {
    let redirectPath = '/';

    if (!user) {
      return redirectPath;
    }

    const roles = user.roles.length > 0 ? user.roles : ['super_admin'];

    roles.forEach((role) => {
      if (redirectPath !== '/') {
        return;
      }

      switch (role) {
        case 'sales':
          redirectPath += `sales/news`;
          break;
        case 'sales.admin':
          redirectPath += `sales/news`;
          break;
        case 'after_sales':
          redirectPath += `after-sales/news`;
          break;
        case 'after_sales.admin':
          redirectPath += `after-sales/news`;
          break;
        case 'marketing':
          redirectPath += `marketing/news`;
          break;
        case 'marketing.admin':
          redirectPath += `marketing/news`;
          break;
        case 'academy':
          redirectPath += `academy/news`;
          break;
        case 'management':
          redirectPath += `management/access-requests`;
          break;
        case 'super_admin':
          redirectPath += `sales/news`;
          break;
        default:
          break;
      }

      // role.access.forEach((access) => {
      //   if (redirectPath !== '/') {
      //     return;
      //   }
      //
      //   const [region, section, permission] = access.split('.');
      //
      //   if (permission === '*' || permission.includes('view')) {
      //     switch (section) {
      //       case 'vehicle_card':
      //       case 'service_history':
      //       case 'technical_campaigns':
      //       case 'epc':
      //       case 'website':
      //         return;
      //       default:
      //         redirectPath += `${region.replaceAll('_', '-')}/${section.replaceAll('_', '-')}`;
      //     }
      //   }
      // });
    });

    return redirectPath;
  }, [user]);

  const redirectPath = useMemo(() => getRedirectPath(), [getRedirectPath]);

  return useMemo(
    () => ({
      hasAccess,
      getData,
      getRedirectPath,
      redirectPath,
    }),
    [hasAccess, getData, getRedirectPath, redirectPath]
  );
}
