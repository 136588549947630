import { Navigate } from 'src/routes/components';
import { Outlet } from 'src/routes/components/outlet';

import { ServerErrorView } from 'src/sections/error';

import { useHasAccess } from 'src/auth/hooks';
import { RoleRouteGuard, AuthGuardWrapper } from 'src/auth/guard';

import { optionsLoader } from '../utils';

// ----------------------------------------------------------------------

export function SalesRoutes() {
  const { hasAccess: hasOrderRequestsAccess, redirectPath } = useHasAccess(['sales.admin', 'super_admin'], 'or');
  const { hasAccess: hasOrdersAccess } = useHasAccess(['sales.admin', 'super_admin'], 'or');

  return [
    {
      path: 'sales',
      element: <AuthGuardWrapper><Outlet /></AuthGuardWrapper>,
      errorElement: <ServerErrorView />,
      children: [
        {
          index: true,
          element: (
            <RoleRouteGuard
              requiredAccess={['sales.dashboard', 'sales.admin.dashboard']}
              operator="or"
              navigateTo={redirectPath}
            >
              <Navigate to="/sales/dashboard" replace />
            </RoleRouteGuard>
          ),
        },
        {
          path: 'dashboard',
          lazy: () => import('src/pages/sales/dashboard'),
        },
        {
          path: 'news',
          children: [
            {
              index: true,
              lazy: () => import('src/pages/sales/news'),
            },
            {
              path: 'view',
              children: [
                {
                  index: true,
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['sales', 'sales.admin', 'super_admin']}
                      operator="or"
                      navigateTo={redirectPath}
                    >
                      <Navigate to="/sales/news" replace />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: ':id',
                  lazy: () => import('src/pages/sales/news/view'),
                },
              ],
            },
          ],
        },
        {
          path: 'inventory',
          lazy: () => import('src/pages/sales/inventory'),
        },
        {
          path: 'order-requests',
          children: [
            {
              index: true,
              lazy: () => import('src/pages/sales/order-requests'),
              loader: () => optionsLoader(hasOrderRequestsAccess, ['company']),
              shouldRevalidate: () => false,
            },
            {
              path: 'view',
              children: [
                {
                  index: true,
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['sales', 'sales.admin', 'super_admin']}
                      operator="or"
                      navigateTo={redirectPath}
                    >
                      <Navigate to="/sales/order-requests" replace />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: ':uuid',
                  lazy: () => import('src/pages/sales/order-requests/view'),
                },
              ],
            },
            {
              path: 'new',
              lazy: () => import('src/pages/sales/order-requests/new'),
            },
          ],
        },
        {
          path: 'orders',
          children: [
            {
              index: true,
              lazy: () => import('src/pages/sales/orders'),
              loader: () => optionsLoader(hasOrdersAccess, ['company']),
              shouldRevalidate: () => false,
            },
            {
              path: 'view',
              children: [
                {
                  index: true,
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['sales', 'sales.admin', 'super_admin']}
                      operator="or"
                      navigateTo={redirectPath}
                    >
                      <Navigate to="/sales/orders" replace />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: ':uuid',
                  lazy: () => import('src/pages/sales/orders/view'),
                },
              ],
            },
          ],
        },
        {
          path: 'sales-reporting',
          children: [
            {
              index: true,
              lazy: () => import('src/pages/sales/sales-reporting'),
            },
            {
              path: 'view',
              lazy: () => import('src/pages/sales/sales-reporting/view'),
            },
          ],
        },
        {
          path: 'price-list',
          children: [
            {
              index: true,
              element: (
                <RoleRouteGuard
                  requiredAccess={['sales', 'sales.admin', 'super_admin']}
                  operator="or"
                  navigateTo={redirectPath}
                >
                  <Navigate to="/sales/price-list/path=" replace />
                </RoleRouteGuard>
              ),
            },
            {
              path: ':path',
              lazy: () => import('src/pages/sales/price-list'),
            },
          ],
        },
        {
          path: 'app-user-binding',
          lazy: () => import('src/pages/sales/app-user-binding'),
        },
        {
          path: 'resources',
          children: [
            {
              index: true,
              element: (
                <RoleRouteGuard
                  requiredAccess={['sales', 'sales.admin', 'super_admin']}
                  operator="or"
                  navigateTo={redirectPath}
                >
                  <Navigate to="/sales/resources/path=" replace />
                </RoleRouteGuard>
              ),
            },
            {
              path: ':path',
              lazy: () => import('src/pages/sales/resources'),
            },
          ],
        },
      ],
    },
  ];
}
