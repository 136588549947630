import type { FallbackProps } from 'react-error-boundary';

import { Helmet } from 'react-helmet';

export function GlobalErrorView({ error }: FallbackProps) {
  return (
    <>
      <Helmet>
        <meta httpEquiv="refresh" content="5" />
      </Helmet>

      <div>
        <p>Currently under maintenance, try again later</p>
        <button type="button" onClick={() => window.location.reload()}>Refresh</button>
      </div>
    </>
  );
}
