import type { Theme, SxProps } from '@mui/material/styles';

import InputAdornment from '@mui/material/InputAdornment';

import { Iconify } from 'src/components/iconify';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>,
};

export function SearchAdornment({ sx }: Props) {
  return (
    <InputAdornment position="start" sx={{ mr: 3.5, ...sx }}>
      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', position: 'absolute' }} />
    </InputAdornment>
  );
}
