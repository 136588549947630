import { Navigate } from 'src/routes/components';
import { Outlet } from 'src/routes/components/outlet';

import { ServerErrorView } from 'src/sections/error';

import { useHasAccess } from 'src/auth/hooks';
import { RoleRouteGuard, AuthGuardWrapper } from 'src/auth/guard';

// ----------------------------------------------------------------------

export function AcademyRoutes() {
  const { redirectPath } = useHasAccess();

  return [
    {
      path: 'academy',
      element: <AuthGuardWrapper><Outlet /></AuthGuardWrapper>,
      errorElement: <ServerErrorView />,
      children: [
        {
          index: true,
          element: (
            <RoleRouteGuard
              requiredAccess={['academy', 'super_admin']}
              operator="or"
              navigateTo={redirectPath}
            >
              <Navigate to="/academy/news" replace />
            </RoleRouteGuard>
          ),
        },
        {
          path: 'news',
          children: [
            {
              index: true,
              lazy: () => import('src/pages/academy/news'),
            },
            {
              path: 'view',
              children: [
                {
                  index: true,
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['academy', 'super_admin']}
                      operator="or"
                      navigateTo={redirectPath}
                    >
                      <Navigate to="/academy/news" replace />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: ':id',
                  lazy: () => import('src/pages/academy/news/view'),
                },
              ],
            },
          ],
        },
        {
          path: 'resources',
          children: [
            {
              index: true,
              element: (
                <RoleRouteGuard
                  requiredAccess={['academy', 'super_admin']}
                  operator="or"
                  navigateTo={redirectPath}
                >
                  <Navigate to="/academy/resources/path=" replace />
                </RoleRouteGuard>
              ),
            },
            {
              path: ':path',
              lazy: () => import('src/pages/academy/resources'),
            },
          ],
        },
      ],
    },
  ];
}
