import { Outlet } from 'src/routes/components/outlet';

import { ServerErrorView } from 'src/sections/error';

// ----------------------------------------------------------------------

export function MainRoutes() {
  return [
    {
      element: <Outlet excludeWrapper splashScreen />,
      errorElement: <ServerErrorView excludeWrapper />,
      children: [
        {
          path: '404',
          lazy: () => import('src/pages/error/404'),
        },
      ],
    },
  ];
}
