import 'src/global.css';

// ----------------------------------------------------------------------

import axios from 'axios';
import { useContext } from 'react';
import { init } from '@sentry/react';
import { hotjar } from 'react-hotjar';
import { Sanctum } from 'react-sanctum';

import { RouterProviderWrapper } from 'src/routes/components/router-provider-wrapper';

import { ThemeProvider } from 'src/theme/theme-provider';

import { Snackbar } from 'src/components/snackbar';
import { FeedbackWindow } from 'src/components/feature-base';
import { ConfigContext } from 'src/components/config-injector';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer, defaultSettings, SettingsProvider } from 'src/components/settings';

// ----------------------------------------------------------------------

export default function App() {
  const { carxo: { frontend: { sanctum, hotjar: hotjarConfig, sentry: { dsn } } } } = useContext(ConfigContext);

  init({ dsn, integrations: [] });

  hotjar.initialize(hotjarConfig);

  return (
    <Sanctum config={{ ...sanctum, axiosInstance: axios.create({ withCredentials: false }) }} checkOnInit={false}>
      <SettingsProvider settings={defaultSettings}>
        <ThemeProvider>
          <MotionLazy>
            <Snackbar />
            <SettingsDrawer hideCompact hideFont />
            <RouterProviderWrapper />
            <FeedbackWindow />
          </MotionLazy>
        </ThemeProvider>
      </SettingsProvider>
    </Sanctum>
  );
}
