import { paths } from 'src/routes/paths';

import { SvgColor } from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`${window.location.origin}/assets/icons/navbar/${name}.svg`} />
);

const ICONS = {
  sales: icon('ic-sales'),
  afterSales: icon('ic-after-sales'),
  marketing: icon('ic-marketing'),
  academy: icon('ic-academy'),
  management: icon('ic-management'),
  administration: icon('ic-administration'),
};

// ----------------------------------------------------------------------

export const navigationData = [
  {
    items: [
      {
        title: 'sales',
        caption: 'revenueAndMetrics',
        path: paths.sales.root,
        icon: ICONS.sales,
        requiredAccess: ['sales', 'sales.admin', 'super_admin'],
        requiredAccessOperator: 'or' as 'or',
        children: [
          // { title: 'dashboard', path: paths.sales.dashboard, requiredAccess: 'sales.dashboard.view_all' },
          {
            title: 'news',
            path: paths.sales.news.root,
            requiredAccess: ['sales', 'sales.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewNews', path: paths.sales.news.view },
            ],
            hideChildren: true,
          },
          {
            title: 'inventory',
            path: paths.sales.inventory,
            requiredAccess: ['sales', 'sales.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
          },
          {
            title: 'orderRequests',
            path: paths.sales.orderRequests.root,
            requiredAccess: ['sales', 'sales.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewOrderRequest', path: paths.sales.orderRequests.view(':uuid') },
              { title: 'newOrderRequest', path: paths.sales.orderRequests.new },
            ],
            hideChildren: true,
          },
          {
            title: 'orders',
            path: paths.sales.orders.root,
            requiredAccess: ['sales', 'sales.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewOrder', path: paths.sales.orders.view(':uuid') },
            ],
            hideChildren: true,
          },
          // {
          //   title: 'salesReporting',
          //   path: paths.sales.salesReporting.root,
          //   requiredAccess: ['sales.sales_reporting.view_all_related', 'sales.sales_reporting.view_all'],
          //   requiredAccessOperator: 'or',
          //   children: [
          //     { title: 'viewSalesReport', path: paths.sales.salesReporting.view },
          //   ],
          //   hideChildren: true,
          // },
          {
            title: 'priceList',
            path: paths.sales.priceList.root,
            requiredAccess: ['sales', 'sales.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewResource', path: paths.sales.priceList.view(':path') },
            ],
            hideChildren: true,
          },
          {
            title: 'appUserBinding',
            path: paths.sales.appUserBinding,
            requiredAccess: ['sales', 'sales.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
          },
          {
            title: 'resources',
            path: paths.sales.resources.root,
            requiredAccess: ['sales', 'sales.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewResource', path: paths.sales.resources.view(':path') },
            ],
            hideChildren: true,
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'afterSales',
        caption: 'customerSupport',
        path: paths.afterSales.root,
        icon: ICONS.afterSales,
        requiredAccess: ['after_sales', 'after_sales.admin', 'super_admin'],
        requiredAccessOperator: 'or' as 'or',
        children: [
          // { title: 'dashboard', path: paths.afterSales.dashboard, requiredAccess: 'after_sales.dashboard.view_all' },
          {
            title: 'news',
            path: paths.afterSales.news.root,
            requiredAccess: ['after_sales', 'after_sales.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewNews', path: paths.afterSales.news.view },
            ],
            hideChildren: true,
          },
          {
            title: 'claims',
            path: paths.afterSales.claims.root,
            requiredAccess: ['after_sales', 'after_sales.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewClaim', path: paths.afterSales.claims.view(':uuid') },
              { title: 'newClaim', path: paths.afterSales.claims.new(':uuid') }
            ],
            hideChildren: true,
          },
          {
            title: 'technicalCampaigns',
            path: paths.afterSales.technicalCampaigns.root,
            requiredAccess: ['after_sales.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewTechnicalCampaign', path: paths.afterSales.technicalCampaigns.view(':uuid') },
              { title: 'newTechnicalCampaign', path: paths.afterSales.technicalCampaigns.new }
            ],
            hideChildren: true,
          },
          {
            title: 'EPC',
            path: paths.afterSales.epc.voyah,
            requiredAccess: ['after_sales', 'after_sales.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'VOYAH', path: paths.afterSales.epc.voyah },
              { title: 'DongFeng', path: paths.afterSales.epc.dongfeng }
            ],
          },
          {
            title: 'spareParts',
            path: paths.afterSales.spareParts.root,
            requiredAccess: ['after_sales', 'after_sales.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'order', path: paths.afterSales.spareParts.order },
            ],
            hideChildren: true,
          },
          {
            title: 'orders',
            path: paths.afterSales.orders.root,
            requiredAccess: ['after_sales', 'after_sales.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewOrder', path: paths.afterSales.orders.view(':uuid') },
            ],
            hideChildren: true,
          },
          {
            title: 'resources',
            path: paths.afterSales.resources.root,
            requiredAccess: ['after_sales', 'after_sales.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewResource', path: paths.afterSales.resources.view(':path') },
            ],
            hideChildren: true,
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'marketing',
        caption: 'campaignsAndGrowth',
        path: paths.marketing.root,
        icon: ICONS.marketing,
        requiredAccess: ['marketing', 'marketing.admin', 'super_admin'],
        requiredAccessOperator: 'or' as 'or',
        children: [
          // { title: 'dashboard', path: paths.marketing.dashboard, requiredAccess: 'marketing.dashboard.view_all' },
          {
            title: 'news',
            path: paths.marketing.news.root,
            requiredAccess: ['marketing', 'marketing.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewNews', path: paths.marketing.news.view },
            ],
            hideChildren: true,
          },
          {
            title: 'merchandise',
            path: paths.marketing.merchandise.root,
            requiredAccess: ['marketing', 'marketing.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'applyForMerchandise', path: paths.marketing.merchandise.apply.clothing },
              { title: 'applyForMerchandise', path: paths.marketing.merchandise.apply.merchandise },
            ],
            hideChildren: true,
          },
          {
            title: 'events',
            path: paths.marketing.events.root,
            requiredAccess: ['marketing', 'marketing.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewEvent', path: paths.marketing.events.view(':uuid') },
              { title: 'newEvent', path: paths.marketing.events.new },
            ],
            hideChildren: true,
          },
          {
            title: 'resources',
            path: paths.marketing.resources.root,
            requiredAccess: ['marketing', 'marketing.admin', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewResource', path: paths.marketing.resources.view(':path') },
            ],
            hideChildren: true,
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'academy',
        caption: 'learningCenter',
        path: paths.academy.root,
        icon: ICONS.academy,
        requiredAccess: ['academy', 'super_admin'],
        requiredAccessOperator: 'or' as 'or',
        children: [
          {
            title: 'news',
            path: paths.academy.news.root,
            requiredAccess: ['academy', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewNews', path: paths.academy.news.view },
            ],
            hideChildren: true,
          },
          {
            title: 'resources',
            path: paths.academy.resources.root,
            requiredAccess: ['academy', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewResource', path: paths.academy.resources.view(':path') },
            ],
            hideChildren: true,
          },
          {
            title: 'website',
            path: paths.academy.external,
            requiredAccess: ['academy', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'management',
        caption: 'teamAndProjects',
        path: paths.management.root,
        icon: ICONS.management,
        requiredAccess: ['management', 'super_admin'],
        requiredAccessOperator: 'or' as 'or',
        children: [
          {
            title: 'accessRequests',
            path: paths.management.accessRequests.root,
            requiredAccess: ['management', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewAccessRequest', path: paths.management.accessRequests.view(':uuid') },
            ],
            hideChildren: true,
          },
          {
            title: 'users',
            path: paths.management.users.root,
            requiredAccess: ['management', 'super_admin'],
            requiredAccessOperator: 'or' as 'or',
            children: [
              { title: 'viewUser', path: paths.management.users.view(':uuid') },
            ],
            hideChildren: true,
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'administration',
        caption: 'systemSettings',
        path: paths.administration.root,
        icon: ICONS.administration,
        requiredAccess: 'super_admin',
        children: [
          {
            title: 'entities',
            path: paths.administration.entities.root(),
            requiredAccess: 'super_admin',
            children: [
              { title: 'viewUser', path: paths.administration.entities.view('user', ':uuid') },
              { title: 'viewRole', path: paths.administration.entities.view('role', ':uuid') },
              { title: 'viewCompany', path: paths.administration.entities.view('company', ':uuid') },
              { title: 'viewClaim', path: paths.administration.entities.view('claim', ':uuid') },
              { title: 'viewEvent', path: paths.administration.entities.view('event', ':uuid') },
              { title: 'newUser', path: paths.administration.entities.new('user') },
              { title: 'newRole', path: paths.administration.entities.new('role') },
              { title: 'newCompany', path: paths.administration.entities.new('company') },
              { title: 'newClaim', path: paths.administration.entities.new('claim') },
              { title: 'newEvent', path: paths.administration.entities.new('event') },
            ],
            hideChildren: true,
          },
          { title: 'storage', path: paths.administration.storage, requiredAccess: 'super_admin' },
          { title: 'smtp', path: paths.administration.smtp, requiredAccess: 'super_admin' },
        ],
      },
    ],
  },
];
