import type { AccessRequest } from 'src/types/auth/request-access';

import { makeRequest } from './utils';

// ----------------------------------------------------------------------

export function logout(): Promise<void> {
  return makeRequest<void, void>({
    type: 'post',
    url: `https://portal2test.electricway.no/auth/logout`,
    data: undefined,
  }).then((response) => response.data);
}

export function createAccessRequest(accessRequest: AccessRequest): Promise<void> {
  return makeRequest<AccessRequest, void>({
    type: 'post',
    url: `https://portal2test.electricway.no/api/frontend/v1/access-request`,
    data: accessRequest,
  }).then((response) => response.data);
}
