export function AuthRoutes() {
  return [
    {
      path: 'login',
      lazy: () => import('src/pages/auth/sign-in'),
    },
    {
      path: 'request-access',
      lazy: () => import('src/pages/auth/request-access'),
    },
  ];
}
