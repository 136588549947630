import { Navigate } from 'src/routes/components';
import { Outlet } from 'src/routes/components/outlet';

import { ServerErrorView } from 'src/sections/error';

import { useHasAccess } from 'src/auth/hooks';
import { RoleRouteGuard, AuthGuardWrapper } from 'src/auth/guard';

import { optionsLoader } from '../utils';

// ----------------------------------------------------------------------

export function AfterSalesRoutes() {
  const { hasAccess: hasClaimsAccess, redirectPath } = useHasAccess(['after_sales.admin', 'super_admin'], 'or');
  const { hasAccess: hasOrdersAccess } = useHasAccess(['after_sales.admin', 'super_admin'], 'or');

  return [
    {
      path: 'after-sales',
      errorElement: <ServerErrorView />,
      element: <AuthGuardWrapper><Outlet /></AuthGuardWrapper>,
      children: [
        {
          index: true,
          element: (
            <RoleRouteGuard
              requiredAccess={['after_sales.dashboard', 'after_sales.admin.dashboard']}
              operator="or"
              navigateTo={redirectPath}
            >
              <Navigate to="/after-sales/dashboard" replace />
            </RoleRouteGuard>
          ),
        },
        {
          path: 'dashboard',
          lazy: () => import('src/pages/after-sales/dashboard'),
        },
        {
          path: 'news',
          children: [
            {
              index: true,
              lazy: () => import('src/pages/after-sales/news'),
            },
            {
              path: 'view',
              children: [
                {
                  index: true,
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['after_sales', 'after_sales.admin', 'super_admin']}
                      operator="or"
                      navigateTo={redirectPath}
                    >
                      <Navigate to="/after-sales/news" replace />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: ':id',
                  lazy: () => import('src/pages/after-sales/news/view'),
                },
              ],
            },
          ],
        },
        {
          path: 'claims',
          children: [
            {
              index: true,
              lazy: () => import('src/pages/after-sales/claims'),
              loader: () => optionsLoader(hasClaimsAccess, ['company']),
              shouldRevalidate: () => false,
            },
            {
              path: 'view',
              children: [
                {
                  index: true,
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['after_sales', 'after_sales.admin', 'super_admin']}
                      operator="or"
                      navigateTo={redirectPath}
                    >
                      <Navigate to="/after-sales/claims" replace />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: ':uuid',
                  lazy: () => import('src/pages/after-sales/claims/view'),
                },
              ],
            },
            {
              path: 'new',
              children: [
                {
                  index: true,
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['after_sales', 'after_sales.admin', 'super_admin']}
                      operator="or"
                      navigateTo={redirectPath}
                    >
                      <Navigate to="/after-sales/claims" replace />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: ':uuid',
                  lazy: () => import('src/pages/after-sales/claims/new'),
                },
              ],
            },
          ],
        },
        {
          path: 'service',
          children: [
            {
              index: true,
              element: (
                <RoleRouteGuard
                  requiredAccess={['after_sales', 'after_sales.admin', 'super_admin']}
                  operator="or"
                  navigateTo={redirectPath}
                >
                  <Navigate to="/after-sales/dashboard" replace />
                </RoleRouteGuard>
              ),
            },
            {
              path: 'new',
              children: [
                {
                  index: true,
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['after_sales', 'after_sales.admin', 'super_admin']}
                      operator="or"
                      navigateTo={redirectPath}
                    >
                      <Navigate to="/after-sales/dashboard" replace />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: ':uuid',
                  lazy: () => import('src/pages/after-sales/service/new'),
                },
              ],
            },
          ],
        },
        {
          path: 'technical-campaigns',
          children: [
            {
              index: true,
              lazy: () => import('src/pages/after-sales/technical-campaigns'),
            },
            {
              path: 'view',
              children: [
                {
                  index: true,
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['after_sales', 'after_sales.admin', 'super_admin']}
                      operator="or"
                      navigateTo={redirectPath}
                    >
                      <Navigate to="/after-sales/dashboard" replace />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: ':uuid',
                  lazy: () => import('src/pages/after-sales/technical-campaigns/view'),
                },
              ],
            },
            {
              path: 'new',
              lazy: () => import('src/pages/after-sales/technical-campaigns/new'),
            },
          ],
        },
        {
          path: 'spare-parts',
          children: [
            {
              index: true,
              lazy: () => import('src/pages/after-sales/spare-parts'),
            },
            {
              path: 'order',
              lazy: () => import('src/pages/after-sales/spare-parts/order'),
            },
          ],
        },
        {
          path: 'orders',
          children: [
            {
              index: true,
              lazy: () => import('src/pages/after-sales/orders'),
              loader: () => optionsLoader(hasOrdersAccess, ['company']),
              shouldRevalidate: () => false,
            },
            {
              path: 'view',
              children: [
                {
                  index: true,
                  element: (
                    <RoleRouteGuard
                      requiredAccess={['after_sales', 'after_sales.admin', 'super_admin']}
                      operator="or"
                      navigateTo={redirectPath}
                    >
                      <Navigate to="/after-sales/orders" replace />
                    </RoleRouteGuard>
                  ),
                },
                {
                  path: ':uuid',
                  lazy: () => import('src/pages/after-sales/orders/view'),
                },
              ],
            },
          ],
        },
        {
          path: 'resources',
          children: [
            {
              index: true,
              element: (
                <RoleRouteGuard
                  requiredAccess={['after_sales', 'after_sales.admin', 'super_admin']}
                  operator="or"
                  navigateTo={redirectPath}
                >
                  <Navigate to="/after-sales/resources/path=" replace />
                </RoleRouteGuard>
              ),
            },
            {
              path: ':path',
              lazy: () => import('src/pages/after-sales/resources'),
            },
          ],
        },
      ],
    },
  ];
}
